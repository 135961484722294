import {
  Analytics,
  CameraIndoor,
  Theaters,
  VideoCall,
  ViewList,
} from '@mui/icons-material';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import CategoryIcon from '@mui/icons-material/Category';
import ClassIcon from '@mui/icons-material/Class';
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';
import ConstructionIcon from '@mui/icons-material/Construction';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import PersonIcon from '@mui/icons-material/Person';
import PodcastsIcon from '@mui/icons-material/Podcasts';
import SsidChartIcon from '@mui/icons-material/SsidChart';
import StreamIcon from '@mui/icons-material/Stream';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import WebIcon from '@mui/icons-material/Web';
import React, { memo, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import MenuItem from '../../@types/menuItem';
import Logo from '../../assets/logo.svg';
import ListMenuItem from './ListMenuItem';
import * as S from './style';

type SideBarProps = {
  isOpen: boolean;
};

function SideBar({ isOpen }: SideBarProps) {
  const [openRegister, setOpenRegister] = useState<boolean>(false);
  const [menuItensRegister, setMenuItensRegister] = useState<any[]>([]);
  const [expandedMenu, setExpandedMenu] = useState<string>();

  const localPath = window.location.pathname;
  const location = useLocation();

  useEffect(() => {
    const fixedMenuItensRegister: MenuItem[] = [
      {
        label: 'Coleções',
        path: '/registration/collections',
        icon: <CollectionsBookmarkIcon />,
      },
      {
        label: 'Autores',
        path: '/registration/author',
        icon: <PersonIcon />,
      },
      {
        label: 'Categorização',
        path: '/registration/categorization',
        icon: <CategoryIcon />,
      },
    ];
    if (!window.location.href.includes('/sebraeplay')) {
      setMenuItensRegister(fixedMenuItensRegister);
    } else {
      setMenuItensRegister([
        {
          label: 'Autores',
          path: '/sebraeplay/author',
          icon: <PersonIcon />,
        },
        {
          label: 'Categorização',
          path: '/registration/categorization',
          icon: <CategoryIcon />,
        },
      ]);
    }

    menuItensRegister.forEach(item => {
      if (localPath.toLowerCase().includes(item.path.toLowerCase())) {
        setOpenRegister(true);
      }
    });

    if (expandedMenu === undefined) {
      switch (location.pathname.split('/')[1]) {
        case 'general':
          setExpandedMenu('Geral');
          break;
        case 'content':
          setExpandedMenu('Conteúdo');
          break;
        case 'registration':
          setExpandedMenu('Cadastros');
          break;
        case 'synchronous':
          setExpandedMenu('Cursos Síncronos');
          break;
        case 'live':
          setExpandedMenu('Ao Vivo');
          break;
        case 'lms':
          setExpandedMenu('LMS');
          break;
        case 'series-category':
          setExpandedMenu('Séries');
          break;
        case 'reports':
          setExpandedMenu('Relatórios');
          break;
        case 'users':
          setExpandedMenu('Usuários');
          break;
        default:
          setExpandedMenu(undefined);
      }
    }
  }, []);

  const menuItens: MenuItem[] = [
    {
      label: 'Artigos',
      path: '/content/article',
      icon: <NewspaperIcon />,
    },
    {
      label: 'Exclusivo',
      path: '/content/exclusive',
      icon: <WebIcon />,
    },
    {
      label: 'Ebooks',
      path: '/content/ebooks',
      icon: <LibraryBooksIcon />,
    },
    {
      label: 'Ferramentas de gestão',
      path: '/content/management-tools',
      icon: <ConstructionIcon />,
    },
    {
      label: 'Infográficos',
      path: '/content/infographics',
      icon: <SsidChartIcon />,
    },
    {
      label: 'Kits Temáticos',
      path: '/content/thematic-kits',
      icon: <HomeRepairServiceIcon />,
    },
    {
      label: 'Pesquisas',
      path: '/content/research',
      icon: <ContentPasteSearchIcon />,
    },
    {
      label: 'Podcasts',
      path: '/content/podcast',
      icon: <PodcastsIcon />,
    },
    {
      label: 'Revistas',
      path: '/content/magazine',
      icon: <ImportContactsIcon />,
    },
    {
      label: 'Vídeos',
      path: '/content/video',
      icon: <VideoLibraryIcon />,
    },
  ];

  const courseSynchronousMenuItens: MenuItem[] = [
    {
      label: 'Turmas',
      path: '/synchronous/class',
      icon: <ClassIcon />,
    },
    {
      label: 'Aulas',
      path: '/synchronous/lesson',
      icon: <MenuBookIcon />,
    },
    {
      label: 'Módulos',
      path: '/synchronous/modules',
      icon: <ViewModuleIcon />,
    },
    {
      label: 'Cursos',
      path: '/synchronous/course',
      icon: <AutoStoriesIcon />,
    },
  ];

  const liveMenuItens: MenuItem[] = [
    {
      label: 'Transmissão Ao Vivo',
      path: '/live/live-stream',
      icon: <StreamIcon />,
    },
  ];

  const lmsMenuItens: MenuItem[] = [
    {
      label: 'Aulas',
      path: '/lms/lessons',
      icon: <MenuBookIcon />,
    },
    {
      label: 'Módulos',
      path: '/lms/modules',
      icon: <ViewModuleIcon />,
    },
    {
      label: 'Cursos',
      path: '/lms/courses',
      icon: <AutoStoriesIcon />,
    },
  ];

  const SeriesMenuItens: MenuItem[] = [
    {
      label: 'Episódios',
      path: '/series-category/Episodes',
      icon: <CameraIndoor />,
    },
    {
      label: 'Temporadas',
      path: '/series-category/seasons',
      icon: <Theaters />,
    },
    {
      label: 'Séries',
      path: '/series-category/series',
      icon: <VideoCall />,
    },
  ];

  const ReportsMenuItens: MenuItem[] = [
    {
      label: 'Geral',
      path: '/reports/general',
      icon: <Analytics />,
    },
    {
      label: 'Cursos e Séries',
      path: '/reportList',
      icon: <ViewList />,
    },
    {
      label: 'Curso Assíncrono | Série X Consumo',
      path: '/reports/course-serie',
      icon: <ViewList />,
    },
    {
      label: 'Registro de Consumo',
      path: '/reports/consumption',
      icon: <Analytics />,
    },
  ];

  const UsersMenuItens: MenuItem[] = [
    {
      label: 'Lista de Usuário',
      path: '/users/list',
      icon: <ViewList />,
    },
    {
      label: 'Lista de Moderadores',
      path: '/users/moderator/list',
      icon: <ViewList />,
    },
    {
      label: 'Área do Palestrante',
      path: '/users/speaker/list',
      icon: <ViewList />,
    },
  ];

  const handleOpenMenu = (label: string) => {
    if (label === expandedMenu) {
      setExpandedMenu('');
    } else {
      setExpandedMenu(label);
    }
  };

  return (
    <S.NavBar>
      <S.LogoContainer>
        <img src={Logo} alt="Logo Sebrae Play" />
      </S.LogoContainer>

      <S.Container>
        <S.Menus>
          <ListMenuItem
            label="Geral"
            handleOpenMenu={label => handleOpenMenu(label)}
            isOpen={expandedMenu === 'Geral'}
            isExpanded={isOpen}
          />

          <ListMenuItem
            label="Conteúdo"
            listMenuItems={menuItens}
            handleOpenMenu={label => handleOpenMenu(label)}
            isOpen={expandedMenu === 'Conteúdo' && isOpen}
            isExpanded={isOpen}
          />

          <ListMenuItem
            label="Cadastros"
            listMenuItems={menuItensRegister}
            icon={
              <AppRegistrationIcon
                style={{ fontSize: 20, color: openRegister ? 'white' : '' }}
              />
            }
            handleOpenMenu={label => handleOpenMenu(label)}
            isOpen={expandedMenu === 'Cadastros' && isOpen}
            isExpanded={isOpen}
          />

          <ListMenuItem
            label="Cursos Síncronos"
            listMenuItems={courseSynchronousMenuItens}
            handleOpenMenu={label => handleOpenMenu(label)}
            isOpen={expandedMenu === 'Cursos Síncronos' && isOpen}
            isExpanded={isOpen}
          />

          <ListMenuItem
            label="Ao Vivo"
            listMenuItems={liveMenuItens}
            handleOpenMenu={label => handleOpenMenu(label)}
            isOpen={expandedMenu === 'Ao Vivo' && isOpen}
            isExpanded={isOpen}
          />

          <ListMenuItem
            label="LMS"
            listMenuItems={lmsMenuItens}
            handleOpenMenu={label => handleOpenMenu(label)}
            isOpen={expandedMenu === 'LMS' && isOpen}
            isExpanded={isOpen}
          />

          <ListMenuItem
            label="Séries"
            listMenuItems={SeriesMenuItens}
            handleOpenMenu={label => handleOpenMenu(label)}
            isOpen={expandedMenu === 'Séries' && isOpen}
            isExpanded={isOpen}
          />

          <ListMenuItem
            label="Relatórios"
            listMenuItems={ReportsMenuItens}
            handleOpenMenu={label => handleOpenMenu(label)}
            isOpen={expandedMenu === 'Relatórios' && isOpen}
            isExpanded={isOpen}
          />

          <ListMenuItem
            label="Usuários"
            listMenuItems={UsersMenuItens}
            handleOpenMenu={label => handleOpenMenu(label)}
            isOpen={expandedMenu === 'Usuários' && isOpen}
            isExpanded={isOpen}
          />
        </S.Menus>
      </S.Container>
    </S.NavBar>
  );
}

export default memo(SideBar);
