import React from 'react';

import Spinner from '../Spinner';
import * as S from './styles';

type CustomLoading = S.WrapperProps;

export default function CustomLoading(props: CustomLoading) {
  return (
    <S.Wrapper {...props}>
      <Spinner isTable />
    </S.Wrapper>
  );
}
