import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    align-items: center;
    background-color: ${theme.colors.primary};
    box-sizing: border-box;
    box-shadow: ${theme.shadow.default};
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    padding: 1rem;
    min-height: 3.75rem;
    width: 100%;

    @media (min-width: 768px) {
      padding: 1rem 1.5rem;
    }
  `}
`;

export const BtnLogout = styled.button`
  ${({ theme }) => css`
    background-color: transparent;
    border: 0;
    color: ${theme.colors.white};
    cursor: pointer;
    font-size: ${theme.font.sizes.xxsmall};
    outline-color: ${theme.colors.white};
  `}
`;

export const Avatar = styled.div`
  ${({ theme }) => css`
    align-items: center;
    background-color: ${theme.colors.sebrae_play_pink_400};
    border-radius: 50%;
    color: ${theme.colors.white};
    display: flex;
    font-size: ${theme.font.sizes.xxsmall};
    font-weight: ${theme.font.bold};
    justify-content: center;
    height: 1.5rem;
    width: 1.5rem;
  `}
`;
