import { Link as LinkIcon, VisibilityOutlined } from '@mui/icons-material';
import { format } from 'date-fns';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import Button from '../../../components/Button';
import { FlexContainer } from '../../../components/FlexContainer';
import { TableProps } from '../../../components/Table';
import { useGetContent, useTablePagination } from '../../../hooks';
import { ContentType } from '../../../models/content';
import ContentFromReponse from '../../../models/from-api-response/content';
import { listContents } from '../../../services/contents';
import ContentTemplate from '../../../templates/ContentTemplate';
import { handleSort } from '../../../utils/handleSort';

export default function SpeakerList() {
  const [content, setContent] = useState<ContentFromReponse[] | []>([]);
  const [orderBySort, setOrderBySort] = useState('DESC');
  const [orderBy, setOrderBy] = useState('UPDATED_AT');

  const { paginationModel, rowCount, setRowCount, changePage, changePageSize } =
    useTablePagination();
  const { getVariedContents, loading } = useGetContent(listContents);
  const inputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  const handleShow = (contentId: string) => {
    navigate(`/live/live-stream/event?id=${contentId}`);
  };

  const handleSearch = async () => {
    const params = {
      limit: paginationModel.pageSize,
      offset: paginationModel.page,
      order_by_sort: orderBySort,
      order_by: orderBy,
      types: [ContentType['LIVE']],
    };

    if (inputRef.current?.value.length)
      params['search'] = inputRef.current?.value;

    await getVariedContents(setContent, setRowCount, params);
  };

  const liveColumns = useMemo(
    () => [
      {
        headerName: 'Título',
        field: 'title',
        minWidth: 200,
        getRowHeight: 100,
        flex: 2,
        renderCell: (cellValues: any) => (
          <p style={{ whiteSpace: 'normal' }}>{cellValues?.value}</p>
        ),
      },
      {
        headerName: 'Data do Evento',
        field: 'start_at',
        flex: 1,
        renderCell: (cellValues: any) => (
          <p style={{ whiteSpace: 'normal' }}>
            {format(new Date(cellValues?.row.event.start_at), 'dd/MM/yyyy')}
          </p>
        ),
      },
      {
        headerName: 'Link',
        field: 'link',
        minWidth: 40,
        getRowHeight: 100,
        flex: 1,
        sortable: false,
        renderCell: () => (
          <a
            href={`${process.env.REACT_APP_FRONT_BASE_URL}/ao-vivo`}
            target="_blank"
            rel="noreferrer"
            style={{ color: '#28969f' }}
          >
            <LinkIcon />
          </a>
        ),
      },
      {
        headerName: 'Criado',
        field: 'created_at',
        flex: 1,
        renderCell: (cellValues: any) => (
          <p style={{ whiteSpace: 'normal' }}>
            {format(new Date(cellValues?.value), 'dd/MM/yyyy')}
          </p>
        ),
      },
      {
        headerName: 'Atualizado',
        field: 'updated_at',
        flex: 1,
        renderCell: (cellValues: any) => (
          <p style={{ whiteSpace: 'normal' }}>
            {format(new Date(cellValues?.value), 'dd/MM/yyyy')}
          </p>
        ),
      },
      {
        headerName: 'Situação',
        field: 'status',
        flex: 1,
        renderCell: (cellValues: any) => (
          <span>
            {cellValues?.value === 'hidden'
              ? 'Ocultado'
              : cellValues?.value === 'draft'
              ? 'Rascunho'
              : 'Publicado'}
          </span>
        ),
      },
      {
        headerName: 'Ações',
        field: 'actions',
        minWidth: 180,
        getRowHeight: 100,
        flex: 1,
        sortable: false,
        renderCell: (cellValues: any) => (
          <FlexContainer
            gap="0.4rem"
            flexWrap="wrap"
            justifyContent="flex-start"
          >
            <Button
              onClick={() => handleShow(cellValues.row.content_id)}
              icon={<VisibilityOutlined fontSize="inherit" />}
              color="secondary"
              title="Visualizar"
            />
          </FlexContainer>
        ),
      },
    ],
    [],
  );

  const breadcrumbs = [
    <Link key="profile" to="/">
      Home
    </Link>,
    <span key="users">Usuários</span>,
    <span key="live-stream">Área do Palestrante</span>,
  ];

  const tableProps: TableProps = {
    columns: liveColumns,
    rows: content,
    keyId: 'content_id',
    onPageChange: page => changePage(page),
    onPageSizeChange: pageSize => changePageSize(pageSize),
    page: paginationModel.page - 1,
    rowsPerPageOptions: [10, 25, 50, 75, 100],
    pageSize: paginationModel.pageSize,
    rowCount,
    loading,
    onSortModelChange: sortModel =>
      handleSort({ changePage, setOrderBy, setOrderBySort, sortModel }),
    disableColumnMenu: true,
  };

  useEffect(() => {
    getVariedContents(setContent, setRowCount, {
      limit: paginationModel.pageSize,
      offset: paginationModel.page,
      order_by_sort: orderBySort,
      order_by: orderBy,
      types: [ContentType['LIVE']],
    });
  }, [paginationModel.page, paginationModel.pageSize, orderBySort, orderBy]);

  return (
    <ContentTemplate
      key={'speaker-list-table'}
      pageName="área do palestrante"
      breadcrumbs={breadcrumbs}
      handleSearch={handleSearch}
      ref={inputRef}
      {...tableProps}
    />
  );
}
