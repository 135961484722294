import { IContentRating } from '../common/interfaces/contentRating';
import { IMetatags } from '../common/interfaces/metaTags';
import { IAuthor } from './author';
import { ICategory } from './category';
import { ICompanyUnit } from './companyUnit';
import { IKnowledge } from './knowledge';
import { IModule } from './module';
import { IRequester } from './requester';
import { ITag } from './tag';
import { ITopic } from './topic';
import { IUser } from './user';

export enum ContentType {
  ARTICLE = 'article',
  COURSE = 'course',
  COURSE_SYNC = 'course_sync',
  EBOOK = 'ebook',
  EXCLUSIVE = 'exclusive',
  INFOGRAPHICS = 'infographics',
  MAGAZINE = 'magazine',
  PODCAST = 'podcast',
  RESEARCH = 'research',
  SERIES = 'series',
  SPREADSHEET = 'spreadsheet',
  THEMATIC_KIT = 'thematic_kit',
  VIDEO = 'video',
  LIVE = 'live',
}

export enum TypeDictionary {
  ARTICLE = 'Artigo',
  COURSE = 'Cursos',
  COURSE_SYNC = 'Cursos Ao Vivo',
  EBOOK = 'Ebook',
  EXCLUSIVE = 'Exclusivo',
  INFOGRAPHICS = 'Infográficos',
  MAGAZINE = 'Revistas',
  PODCAST = 'Podcast',
  RESEARCH = 'Pesquisas',
  SERIES = 'Séries',
  SPREADSHEET = 'Ferramentas de Gestão',
  THEMATIC_KIT = 'Kits Temáticos',
  VIDEO = 'Videos',
  LIVE = 'Transmissão ao vivo',
}

export enum ContentStatus {
  PUBLISHED = 'published',
  DRAFT = 'draft',
  HIDDEN = 'hidden',
}

export enum ContentVisibility {
  PUBLIC = 'public',
  RESTRICTED = 'restricted',
}

export interface IContentProps {
  content_id: string;
  type: string;
  slug: string;
  title: string;
  review_in: string | Date;
  published_at?: string | Date;
  updated_at: string;
  status: string;
  featured_start_at?: string | Date;
  featured_end_at?: string | Date;
  published_end_at?: string | Date;
}

export interface IContentUpdateHistory {
  content_history_id: string;
  created_at: Date | string;
  user_id: string;
  content_id: string;
  user: IUser;
}

export interface IContentFile {
  file_id: string;
  reference: string;
  name: string;
  original_name: string;
  mime_type: string;
  type: string;
  size: string;
  tooltip: string | null;
  permission: string;
  created_at: Date;
  updated_at: Date;
}

export interface IContent {
  audio_file: IContentFile | null;
  audio_file_id: string | null;
  content_id: string;
  type:
    | 'article'
    | 'ebook'
    | 'infographics'
    | 'video'
    | 'research'
    | 'thematic_kit'
    | 'magazine'
    | 'spreadsheet'
    | 'podcast'
    | 'course'
    | 'series';
  visibility: 'public' | 'restricted';
  groups?: string[] | null;
  page_title: string;
  slug: string;
  title: string;
  subtitle: string;
  reference: string | null;
  resume: string;
  description: string;
  aditional_description?: string | null;
  views: number;
  antecipation: boolean | null;
  antecipation_days?: number | null;
  duration: number | null;
  antecipation_groups?: string[] | null;
  image_file_id: string | null;
  image_name: string;
  image_toltip: string;
  file_id?: string | null;
  file_name: string | null;
  file_toltip?: string | null;
  allow_download: boolean | null;
  show_page_flip: boolean | null;
  metatags: IMetatags;
  review_in: string;
  published_at: string | null;
  created_at: string;
  updated_at: string;
  deleted_at?: string | null;
  status: 'published' | 'draft' | 'hidden';
  requester_id: string;
  level: 'basic' | 'intermediary' | 'advanced';
  sebrae_code: string | null;
  days_to_complete: number | null;
  edition?: string | null;
  part?: string | null;
  featured_start_at: string | null;
  featured_end_at: string | null;
  extension?: string | null;
  rating: IContentRating[];
  published_start_at: string | null;
  published_end_at: string | null;
  estimated_time: string | null;
  is_free: boolean | null;
  price: number;
  file?: File;
  image_file: InterfaceImageFile;
  authors: IAuthor[];
  collections: any[];
  topics: ITopic[];
  tags: ITag[];
  requester: IRequester;
  company_sizes: any[];
  company_units: ICompanyUnit[];
  categories: ICategory[];
  complement_files: any[];
  complement_contents: ComplementContent[];
  episodes: any[];
  modules: IModule[];
  knowledges: IKnowledge[];
  update_history?: IContentUpdateHistory[];
  total_download: number;
  total_download_valid: number;
  total_download_registered: number;
  total_consumptions: number;
  total_consumptions_valid: number;
  total_consumptions_registered: number;
  creator?: {
    name: string;
    email: string;
  } | null;
  portfolio_id: string | null;
  event: IEvent | null;
  thumbnail_file_id: string | null;
  thumbnail_file: string | null;
  thumbnail_name: string | null;
  thumbnail_tooltip: string | null;
}

export interface IEvent {
  chat_url: string | null;
  event_id: string;
  transmission_id: string;
  channel_id: string;
  status: string;
  finish_at: string;
  start_at: string;
  reference: string;
  encoder_url: string;
  transmission_key: string;
  thumbnail_id: string;
  created_at: string;
  updated_at: string;
}

export interface InterfaceImageFile {
  file_id: string;
  reference: string;
  name: string;
  original_name: string;
  mime_type: string;
  type: string;
  size: string;
  tooltip?: any;
  permission: string;
  created_at: Date | string;
  updated_at: Date | string;
  categories: ICategory[];
}

export interface ComplementContent {
  content_id: string;
  type: string;
  visibility: string;
  groups: string[];
  page_title: string;
  slug: string;
  title: string;
  subtitle: string;
  reference: string;
  resume: string;
  description: string;
  aditional_description?: any;
  views: number;
  antecipation: boolean;
  antecipation_days?: any;
  duration: number;
  antecipation_groups?: any;
  image_file_id: string;
  image_name: string;
  image_toltip: string;
  file_id: string;
  file_name: string;
  file_toltip: string;
  allow_download: boolean;
  show_page_flip: boolean;
  metatags: any;
  review_in: Date;
  published_at: Date;
  created_at: Date;
  updated_at: Date;
  deleted_at?: any;
  status: string;
  requester_id: string;
  level: string;
  sebrae_code: string;
  days_to_complete?: number;
  edition?: any;
  part?: any;
  featured_start_at?: Date;
  featured_end_at?: Date;
  extension?: any;
  rating: string;
  published_start_at?: Date;
  published_end_at?: Date;
  estimated_time: string;
  is_free: boolean;
  price: string;
}

export interface contentHistory {
  id: string;
  content_id: string;
  last_change_at: string;
  user_id: string;
  user_name: string;
}

export interface IContentParams {
  limit?: number;
  offset?: number;
  search?: string;
  types?: string[];
  categories?: string[];
  company_size?: string[];
  authors?: string[];
  topics?: string[];
  collections?: string[];
  status?: 'published' | 'draft' | 'hidden';
  visibility?: 'public' | 'restricted';
  published_start_at?: string | Date;
  published_end_at?: string | Date;
  review_start_at?: string | Date;
  review_end_at?: string | Date;
  order_by?: string;
  order_by_sort?: 'ASC' | 'DESC';
}
