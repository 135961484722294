import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    align-items: center;
    background: linear-gradient(0deg, #354371 0%, #d76184 86.6%, #f2557a 100%);
    color: ${theme.colors.white};
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 100%;
    overflow-y: scroll;
    padding: 1.75rem;
    position: absolute;

    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  `}
`;

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 58rem;
  width: 100%;
`;

export const PlayerContainer = styled.div`
  ${({ theme }) => css`
    align-items: center;
    background-color: black;
    border-radius: 24px;
    color: ${theme.colors.white};
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 30rem;
    width: 100%;

    & span:first-of-type {
      font-size: ${theme.font.sizes.xxlarge};
      font-weight: ${theme.font.semiBold};
      line-height: 40px;
    }

    & > span:nth-of-type(2) {
      font-size: ${theme.font.sizes.large};
      font-weight: ${theme.font.semiBold};
    }
  `}
`;

type BtnTransmissionProps = {
  isPlay: boolean;
};

export const BtnTransmission = styled.button<BtnTransmissionProps>`
  ${({ theme, isPlay }) => css`
    align-self: flex-start;
    background-color: ${isPlay
      ? theme.colors.red_400
      : theme.colors.sebrae_play_pink_400};
    border: none;
    border-radius: ${theme.border.radius};
    color: ${theme.colors.white};
    cursor: pointer;
    font-size: ${theme.font.sizes.xsmall};
    font-weight: ${theme.font.bold};
    margin-left: 23px;
    padding: 12px 20px;

    span {
      align-items: center;
      display: flex;
      gap: 4px;
    }

    svg {
      animation: blink 1s infinite;
      font-size: 14px;
    }

    @keyframes blink {
      0%,
      100% {
        opacity: 1;
      }
      50% {
        opacity: 0;
      }
    }
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.white};
    display: flex;
    flex-direction: column;
    gap: 28px;
    max-width: 95%;
    overflow-wrap: break-word;
    padding-top: 38px;
    width: 100%;

    h1,
    h2 {
      font-size: ${theme.font.sizes.xlarge};
      font-weight: ${theme.font.bold};
      line-height: 30px;
      margin-bottom: 12px;
    }

    p,
    label {
      font-size: ${theme.font.sizes.large};
      font-weight: ${theme.font.semiBold};
      line-height: 24px;
    }
  `}
`;

type LabelProps = {
  isCopy: boolean;
};

export const Label = styled.label<LabelProps>`
  ${({ theme, isCopy }) => css`
    color: ${theme.colors.white};

    div,
    input {
      color: black;
      font-size: ${theme.font.sizes.large};
      font-weight: ${theme.font.semiBold};
    }

    div {
      align-items: center;
      border-radius: ${theme.border.radius};
      background-color: #d9d9d9;
      cursor: pointer;
      display: flex;
      flex-wrap: nowrap;
      padding: 14px 32px;
      margin-top: 8px;
    }

    input {
      border: none;
      background-color: transparent;
      height: 100%;
      width: 100%;
    }

    span {
      color: ${isCopy ? theme.colors.green_400 : 'black'};
    }
  `}
`;

export const Line = styled.div`
  ${({ theme }) => css`
    align-items: center;
    color: ${theme.colors.white};
    display: flex;
    flex-wrap: nowrap;
    font-size: ${theme.font.sizes.medium};
    font-weight: ${theme.font.semiBold};
    gap: 1rem;
    overflow-wrap: break-word;
    margin-bottom: 26px;

    svg {
      font-size: 50px;
    }
  `}
`;
