import { convertTimeToMilliseconds } from '../helpers/convertTimeToMilliseconds';
import { SeriesFormValuesType } from '../pages/LMS/Series/SerieEdit';

type HookProps = {
  data: SeriesFormValuesType;
  status: string;
  id?: string;
};

export default function useSerieSubmit({ data, status, id }: HookProps) {
  const obj = { type: 'series', status: status };
  Object.assign(obj, data);

  obj['company_units'] = data['company_units'].map(company => company.value);

  if (data['visibility'] === 'restricted' && data['groups']) {
    obj['groups'] = data['groups'].map(group => group.value);
  }

  if (data['visibility'] !== 'restricted' && data['groups']?.length) {
    delete obj['groups'];
  }

  if (!obj['image_file']) {
    obj['image_name'] = `Sebrae Minas - ${obj['title']}`;
    obj['image_toltip'] = `Sebrae Minas - ${obj['title']}`;
  }

  if (obj['image_file'] || obj['image_file'] === null) {
    delete obj['image_file'];
  }

  if (obj['tooltip']) delete obj['tooltip'];
  if (obj['requester']) delete obj['requester'];
  if (!obj['reference']) delete obj['reference'];
  if (!obj['content_id']) obj['content_id'] = id;
  if (obj['tags']) obj['tags'] = data['tags'].map(tag => tag.tag_id);
  if (obj['topics'])
    obj['topics'] = data['topics'].map(topic => topic.topic_id);
  if (obj['knowledges'])
    obj['knowledges'] = data['knowledges'].map(
      knowledge => knowledge.knowledge_id,
    );

  if (
    obj['duration'] &&
    (typeof obj['duration'] === 'string' || obj['duration'] instanceof String)
  ) {
    const durationMilliseconds: number = convertTimeToMilliseconds(
      obj['duration'].toString(),
    );
    obj['duration'] = durationMilliseconds;
  }

  return { obj };
}
