import { z } from 'zod';

const requiredMessage = 'Campo obrigatório';

const meetingsSchema = z.object({
  sebrae_id: z.string({ required_error: 'O id do encontro é obrigatório' }),
  start_at: z.date({ required_error: 'A data do encontro é obrigatório' }),
  finish_at: z.date({ required_error: 'A data do encontro é obrigatório' }),
});

const moderator = z.object({
  moderator_id: z.string(),
  name: z.string(),
  email: z.string(),
  cpf: z.string(),
  created_at: z.string(),
  updated_at: z.string(),
});

const user = z.object({
  author_id: z.string().nullable(),
  moderator_id: z.string().nullable(),
  companies: z.array(z.any()).optional(),
  cpf: z.string().nullable(),
  created_at: z.string(),
  email: z.string(),
  is_admin: z.boolean().nullable(),
  name: z.string(),
  user_id: z.string(),
});

export const syncClassSchema = z.object({
  audience: z.enum(['open', 'closed'], {
    required_error: requiredMessage,
    invalid_type_error: 'O tipo de público deve ser "Aberto" ou "Fechado"',
  }),
  description: z.string().nonempty(requiredMessage),
  duration: z.string().nonempty(requiredMessage),
  finish_at: z.date({ required_error: requiredMessage }).nullable(),
  instructor_cpf: z.string().nonempty(requiredMessage),
  instructor_name: z.string().nonempty(requiredMessage),
  instructor_email: z.string().nonempty(requiredMessage),
  instructor_is_email_cpf_blocked: z.boolean(),
  instructor_slug: z.string().nonempty(requiredMessage),
  moderators: z.string().uuid().array().optional(),
  moderators_selected: z.array(moderator).optional(),
  meetings: z.array(meetingsSchema).optional(),
  name: z.string().nonempty(requiredMessage),
  price: z
    .string({
      required_error: requiredMessage,
    })
    .regex(/([0-9])/, requiredMessage),
  start_at: z.date({ required_error: requiredMessage }).nullable(),
  status: z.enum(['active', 'inactive'], {
    required_error: requiredMessage,
    invalid_type_error: 'O status deve ser "Ativo" ou "Inativo"',
  }),
  sebrae_code: z.string().nonempty(requiredMessage),
  sebrae_code_content: z.string().nonempty(requiredMessage),
  users: z.string().uuid().array().optional(),
  users_selected: z.array(user).optional(),
  vacancies: z
    .string({
      required_error: requiredMessage,
    })
    .regex(/([0-9])/, requiredMessage),
});
