import { addMilliseconds, format, startOfDay } from 'date-fns';

export function convertMsToHour(milliseconds: number) {
  const startDate = new Date();
  const startOfDayDate = startOfDay(startDate);
  const resultDate = addMilliseconds(startOfDayDate, milliseconds);
  const formattedDate = format(resultDate, 'HH:mm');

  return formattedDate;
}
