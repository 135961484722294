import {
  AddCircle,
  Delete,
  HideSource,
  Save,
  SaveAs,
} from '@mui/icons-material';
import { DatePicker } from '@mui/lab';
import * as M from '@mui/material';
import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { MultiValue } from 'react-select';
import { Option } from 'react-select/src/filters';
import Swal from 'sweetalert2';

import { KeyIds } from '../../common/enum/keyIds';
import { ContentStatusType } from '../../common/types/contentStatusType';
import { DataOptionType } from '../../common/types/DataOption';
import { KeyModalType } from '../../common/types/keyModal';
import { modalWithoutSchema } from '../../common/validations/modalWithoutSchema';
import Button from '../../components/Button';
import CustomSelect from '../../components/CustomSelect';
import { FlexContainer } from '../../components/FlexContainer';
import Authors from '../../components/FormComponent/Authors';
import Card from '../../components/FormComponent/Card';
import Categorization from '../../components/FormComponent/Categorization';
import Form from '../../components/FormComponent/Form';
import TextField from '../../components/FormComponent/TextField';
import GenericDragAndDrop from '../../components/GenericDragAndDrop';
import InvalidFeedback from '../../components/InvalidFeedback';
import Loading from '../../components/Loading';
import RegisterModal from '../../components/SimpleModal/RegisterModal';
import UploadCover from '../../components/UploadCover';
import { requiredMessage } from '../../constants/ErroMessages';
import { useApplicant } from '../../hooks';
import { IContent } from '../../models/content';
import { createCompanyUnit } from '../../services/company';
import { getChannelsList } from '../../services/live';
import { createRequest } from '../../services/requester';
import { createTag, getAllTags } from '../../services/tags';
import { dealWithTitleChange } from '../../utils';

type LiveStreamTemplateProps = {
  title: string;
  content?: IContent;
  view: boolean;
  isLoading?: boolean;
  submit: (data, status: ContentStatusType) => void;
};

type Item = {
  [key: string]: any;
};

type Key = 'company_units' | 'requester';

export default function LiveStreamTemplate({
  content,
  title,
  view,
  submit,
  isLoading,
}: LiveStreamTemplateProps) {
  const [openModal, setOpenModal] = useState({
    companyUnits: false,
    requester: false,
  });

  const {
    setValue,
    watch,
    getValues,
    formState: { errors },
    clearErrors,
    setError,
    control,
    handleSubmit,
  } = useFormContext();
  const [channelOption, setChannelOption] = useState<Option[] | []>();

  const { optionsToSelect, setOptionsToSelect } = useApplicant();
  const navigate = useNavigate();

  const handleChannelList = async () => {
    try {
      const channels = await getChannelsList();

      setChannelOption(
        channels.map(channel => ({
          label: channel.name,
          value: channel.id,
          data: channels,
        })),
      );
    } catch (error: any) {
      console.error(error);

      Swal.fire({
        icon: 'error',
        iconColor: '#D73E51',
        title: 'Erro ao buscar os canais no Samba Videos',
        text: error.message,
        customClass: {
          confirmButton: 'btn-ok',
        },
      });
    }
  };

  useEffect(() => {
    handleChannelList();
  }, []);

  const handleTitleChange = e => {
    setValue('page_title', e.target.value);
    const slug = dealWithTitleChange(e.target.value);
    setValue('slug', slug);

    if (Object.keys(errors).includes('page_title')) {
      clearErrors('page_title');
      clearErrors('slug');
    }
  };

  const handleOpenModal = (key: KeyModalType) => {
    setOpenModal(prevState => ({ ...prevState, [key]: !openModal[key] }));
  };

  const handleMuiltSelected = <T extends Item>(item: T, key: Key) => {
    const keyIds = getValues(key);

    keyIds === undefined
      ? setValue(key, [{ label: item.label, value: item.value }])
      : setValue(key, [...keyIds, { label: item.label, value: item.value }]);
  };

  const handleSelected = <T extends Item>(item: T, key: Key) => {
    setValue(key, { label: item.label, value: item.value });
  };

  const handleSelectChange = {
    'select-option': (key: string, option: MultiValue<readonly Option[]>) => {
      setValue(key, option);
    },
    'remove-value': (key: string, option: MultiValue<readonly Option[]>) => {
      setValue(key, option);
    },
    clear: (key: string, option: MultiValue<readonly Option[]>) => {
      setValue(key, option);
    },
  };

  const warning = () => {
    Swal.fire({
      icon: 'error',
      iconColor: '#D73E51',
      title: 'Erro',
      text: 'A alteração pode ser prejudicial ao ranqueamento das ferramentas de busca.',
      customClass: {
        confirmButton: 'btn-ok',
      },
    });
  };

  const onError = error => {
    console.error(error);
    Swal.fire({
      icon: 'error',
      title: 'Erro ao enviar o formulário',
      text: 'Por favor, preencha o formulário corretamente.',
      customClass: {
        title: 'modal-title',
        htmlContainer: 'modal-html-container',
        confirmButton: 'btn-ok',
      },
      buttonsStyling: false,
    });
  };

  const onCancel = () => {
    Swal.fire({
      icon: 'question',
      title: 'Confirmação',
      html: 'Tem certeza que deseja cancelar as alterações?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
      focusConfirm: true,
      customClass: {
        title: 'modal-title',
        htmlContainer: 'modal-html-container',
        confirmButton: 'btn-ok',
        cancelButton: 'btn-cancel',
      },
      buttonsStyling: false,
    }).then(async result => {
      if (result.isConfirmed) {
        navigate(-1);
      }
    });
  };

  useEffect(() => {
    const authors = getValues('authors');
    const categories = getValues('categories');

    if (!authors.length) {
      setError('authors', { message: requiredMessage });
    }

    if (authors.length > 4) {
      setError('authors', { message: 'Só pode selecionar até 4 autores' });
    }

    if (authors.length > 0 && authors.length <= 4 && errors['authors']) {
      clearErrors('authors');
    }

    if (!categories.length) {
      setError('categories', { message: requiredMessage });
    }

    if (categories.length && errors['categories']) {
      clearErrors('categories');
    }
  }, [watch('authors'), watch('categories')]);

  if (isLoading) return <Loading />;

  return (
    <Card title={title} contentId={content ? content.content_id : undefined}>
      <Form style={{ paddingBottom: '0.875rem' }}>
        <FlexContainer gap="0.875rem" flexWrap="wrap">
          <TextField
            label="Título da página"
            name="page_title"
            onChange={handleTitleChange}
            disabled={view}
            flex="1 0 13.75rem"
            required
          />
          <TextField
            label="Slug | URL"
            name="slug"
            onClick={warning}
            disabled={view}
            flex="1 0 13.75rem"
            required
          />
          <TextField
            label="Título"
            name="title"
            disabled={view}
            flex="1 0 13.75rem"
            required
          />
          <TextField
            label="Subtítulo"
            name="subtitle"
            disabled={view}
            flex="1 0 13.75rem"
            required
          />
          <TextField
            label="Link do chat"
            name="event.chat_url"
            disabled={view}
            flex="1 0 13.75rem"
            required
          />
          <TextField
            label="Resumo"
            name="resume"
            disabled={view}
            flex="1"
            required
          />
        </FlexContainer>

        <FlexContainer gap="0.875rem" flexWrap="wrap">
          <TextField
            label="Id do Canal"
            name="event.channel_id"
            flex="1 0 13.75rem"
            required
          >
            <CustomSelect
              id="event.channel_id"
              isClearable
              isRtl={false}
              isSearchable
              options={channelOption}
              onChange={value => {
                value && setValue('event.channel_id', value.value);
              }}
              value={channelOption?.find(
                channel => channel.value === watch('event.channel_id'),
              )}
              placeholder="Selecione o canal"
              thereIsError={
                errors['events'] &&
                Object.keys(errors['events'])?.includes('channel_id')
              }
              isDisabled={view}
            />
          </TextField>
          <TextField
            label="Data início"
            name="event.start_at"
            flex="1 0 15rem"
            required
          >
            <DatePicker
              disabled={view}
              value={watch('event.start_at') || null}
              onChange={date => setValue('event.start_at', date)}
              renderInput={params => <M.TextField {...params} />}
            />
          </TextField>
          <TextField
            label="Data fim"
            name="event.finish_at"
            flex="1 0 15rem"
            required
          >
            <DatePicker
              disabled={view}
              value={watch('event.finish_at') || null}
              onChange={date => setValue('event.finish_at', date)}
              renderInput={params => <M.TextField {...params} />}
            />
          </TextField>
        </FlexContainer>

        <FlexContainer gap="0.875rem" flexWrap="wrap">
          <TextField
            label="Unidade Solicitante"
            name="company_units"
            flex="1 0 13.75rem"
            required
          >
            <CustomSelect
              id="company_units"
              isMulti
              isClearable
              isRtl={false}
              isSearchable
              options={optionsToSelect.company_units}
              onChange={(values, action) => {
                handleSelectChange[action.action]('company_units', values);
              }}
              value={watch('company_units')}
              placeholder="Selecione as unidades"
              thereIsError={Object.keys(errors).includes('company_units')}
              isDisabled={view}
            />
            <div>
              <Button
                startIcon={<AddCircle />}
                variant="contained"
                type="button"
                onClick={() => handleOpenModal('companyUnits')}
                disabled={view}
              >
                Adicionar novo
              </Button>
            </div>
          </TextField>
          <TextField
            label="Nome Solicitante "
            name="requester"
            flex="1 0 13.75rem"
            required
          >
            <CustomSelect
              id="requester"
              isClearable
              isRtl={false}
              isSearchable
              options={optionsToSelect.requesters}
              onChange={(value, action) => {
                handleSelectChange[action.action]('requester', value);
                value && setValue('requester_id', value.value);
              }}
              value={optionsToSelect.requesters.find(
                requester => requester.value === watch('requester_id'),
              )}
              placeholder="Selecione o solicitante"
              thereIsError={Object.keys(errors).includes('requester')}
              isDisabled={view}
            />
            <div>
              <Button
                startIcon={<AddCircle />}
                variant="contained"
                type="button"
                onClick={() => handleOpenModal('requester')}
                disabled={view}
              >
                Adicionar novo
              </Button>
            </div>
          </TextField>
        </FlexContainer>

        <FlexContainer gap="0.875rem" flexDirection="column">
          <FlexContainer gap="0.875rem" flexWrap="wrap" width="100%">
            <div style={{ width: '100%' }}>
              <Authors
                clearErrors={clearErrors}
                setError={setError}
                control={control}
                setValue={setValue}
                content={content}
                view={view}
                required
              />
              {errors['authors'] && (
                <InvalidFeedback message={errors['authors'].message} />
              )}
            </div>
            <GenericDragAndDrop
              view={view}
              keyId={KeyIds.TAGS}
              createItens={createTag}
              getItens={getAllTags}
              name="tags"
              label="Tags"
              needSlug
              required
            />
            <Categorization
              control={control}
              setValue={setValue}
              content={content}
              view={view}
            />
          </FlexContainer>

          <FlexContainer gap="0.875rem" flexWrap="wrap" width="100%">
            <TextField
              label="Miniatura (Tamanho 921x466px)"
              name="thumbnail_file_id"
              flex="1 1"
            >
              <UploadCover
                isView={view}
                keyFileId="thumbnail_file_id"
                keyImageFile="thumbnail_file"
                keyImageName="thumbnail_name"
                keyImageTooltip="thumbnail_tooltip"
                sizes={{
                  width: 921,
                  heigth: 466,
                }}
              />
            </TextField>
          </FlexContainer>
        </FlexContainer>
      </Form>

      <FlexContainer gap="0.875rem" flexWrap="wrap" justifyContent="center">
        <Button
          type="button"
          variant="contained"
          color="blue"
          onClick={() =>
            handleSubmit(data => submit(data, 'published'), onError)()
          }
          startIcon={<Save />}
          disabled={view}
        >
          Publicar
        </Button>
        <Button
          type="button"
          variant="outlined"
          onClick={() => handleSubmit(data => submit(data, 'draft'), onError)()}
          disabled={view}
          startIcon={<SaveAs />}
        >
          Salvar Rascunho
        </Button>
        <Button
          type="button"
          variant="contained"
          color="info"
          startIcon={<HideSource />}
          onClick={() => {
            Swal.fire({
              title: '<strong>Confirmação</strong>',
              html: 'Tem certeza que deseja ocultar o curso?',
              showCancelButton: true,
              cancelButtonText: 'Cancelar',
              focusConfirm: false,
              buttonsStyling: false,
              reverseButtons: true,
              customClass: {
                confirmButton: 'btn-ok',
                cancelButton: 'btn-cancel',
                title: 'modal-title',
                htmlContainer: 'modal-html-container',
              },
            }).then(async result => {
              if (result.isConfirmed) {
                handleSubmit(data => submit(data, 'hidden'), onError)();
              }
            });
          }}
          disabled={view}
        >
          Ocultar
        </Button>
        <Button
          type="button"
          variant="contained"
          color="secondary"
          onClick={onCancel}
          disabled={view}
          aria-disabled={view}
          startIcon={<Delete />}
        >
          Cancelar
        </Button>
      </FlexContainer>

      {openModal.companyUnits &&
        createPortal(
          <RegisterModal
            schema={modalWithoutSchema}
            isOpen={openModal.companyUnits}
            onCloseChange={() => handleOpenModal('companyUnits')}
            keyId={'company_unit_id'}
            funcCreate={createCompanyUnit}
            handleOptionChange={data =>
              handleMuiltSelected(data, 'company_units')
            }
            needSlug={false}
          />,
          document.body,
        )}
      {openModal.requester &&
        createPortal(
          <RegisterModal
            schema={modalWithoutSchema}
            isOpen={openModal.requester}
            onCloseChange={() => handleOpenModal('requester')}
            keyId={'requester_id'}
            funcCreate={createRequest}
            handleOptionChange={(data: DataOptionType) => {
              const requesters = [...optionsToSelect.requesters];
              requesters.push({
                label: data.label,
                value: data.value,
                data: data.data,
              });

              handleSelected(data, 'requester');
              setValue('requester_id', data.value);
              setOptionsToSelect(prev => ({ ...prev, requesters }));
            }}
            needSlug={false}
          />,
          document.body,
        )}
    </Card>
  );
}
