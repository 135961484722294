import { zodResolver } from '@hookform/resolvers/zod';
import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Swal from 'sweetalert2';

import { ContentStatusType } from '../../../../common/types/contentStatusType';
import { LiveContentSchema } from '../../../../common/validations/liveValidation';
import { useContentSubmit } from '../../../../hooks';
import { IContent } from '../../../../models/content';
import { getContentById, updateContent } from '../../../../services/contents';
import LiveStreamTemplate from '../../../../templates/LiveStreamTemplate';
import { LIVE_INITAL_VALUES, LiveFormValues } from '../Create';

export default function LiveStreamEdit() {
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState<IContent>();

  const [searchParams] = useSearchParams();
  const methods = useForm<LiveFormValues>({
    resolver: zodResolver(LiveContentSchema),
    defaultValues: LIVE_INITAL_VALUES,
  });
  const navigate = useNavigate();
  const id = searchParams.get('id');
  const isView = useMemo(() => !!searchParams.get('view'), [searchParams]);

  const getContent = async (id: string | null): Promise<void> => {
    if (!id) {
      Swal.fire({
        icon: 'error',
        iconColor: '#f5365c',
        title: 'Erro ao buscar o conteúdo!',
        text: 'O id passado na url é inválido',
        buttonsStyling: false,
        customClass: {
          title: 'modal-title',
          htmlContainer: 'modal-html-container',
          confirmButton: 'btn-ok',
        },
      }).then(() => navigate('/live/live-stream'));
      return;
    }

    setLoading(true);

    try {
      const { setValue } = methods;
      const resp = await getContentById(id);

      setValue(
        'authors',
        resp.authors.map(author => author.author_id),
      );
      setValue('event.chat_url', resp.event?.chat_url || '');
      setValue('event.channel_id', resp.event?.channel_id || '');
      setValue(
        'event.finish_at',
        !resp.event?.start_at ? new Date() : new Date(resp.event?.start_at),
      );
      setValue(
        'event.start_at',
        !resp.event?.finish_at ? new Date() : new Date(resp.event?.finish_at),
      );
      setValue('page_title', resp.page_title);
      setValue('resume', resp.resume);
      setValue('slug', resp.slug);
      setValue('subtitle', resp.subtitle);
      setValue('tags', resp.tags);
      setValue('title', resp.title);
      setValue(
        'company_units',
        resp.company_units.map(company => ({
          label: company.name,
          value: company.company_unit_id,
        })),
      );
      setValue('requester', {
        label: resp.requester.name,
        value: resp.requester.requester_id,
      });
      setValue('requester_id', resp.requester_id);
      setValue('tags', resp.tags);
      setValue('categories', resp.categories);
      setValue('thumbnail_file_id', resp.thumbnail_file_id || '');
      setValue('thumbnail_file', resp.thumbnail_file);
      setValue('thumbnail_name', resp.thumbnail_name);
      setValue('thumbnail_tooltip', resp.thumbnail_tooltip);

      setContent(resp);
    } catch (error: any) {
      console.error(error);
      Swal.fire({
        icon: 'error',
        iconColor: '#f5365c',
        title: 'Erro ao buscar o conteúdo!',
        text: error.message || error,
        customClass: {
          confirmButton: 'btn-ok',
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const submit = (data: LiveFormValues, status: ContentStatusType) => {
    const { obj } = useContentSubmit({ data });

    delete obj['thumbnail_file'];
    delete obj['thumbnail_tooltip'];

    obj['company_units'] = data['company_units'].map(company => company.value);
    obj['tags'] = data['tags'].map(tag => tag.tag_id);
    obj['status'] = status;
    obj['type'] = 'live';
    obj['description'] = data['resume'];
    obj['review_in'] = data['event'].finish_at;
    obj['content_id'] = id;

    updateContent(obj)
      .then(() => {
        Swal.fire({
          title: 'Transmissão editada com sucesso',
          icon: 'success',
          customClass: {
            title: 'modal-title',
            htmlContainer: 'modal-html-container',
            confirmButton: 'btn-ok',
          },
          buttonsStyling: false,
        }).then(() => navigate(-1));
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'Algo deu errado',
          iconColor: '#f5365c',
          text: error.message,
          customClass: {
            title: 'modal-title',
            htmlContainer: 'modal-html-container',
            confirmButton: 'btn-ok',
          },
          buttonsStyling: false,
        });
      });
  };

  useEffect(() => {
    getContent(id);
  }, []);

  return (
    <FormProvider {...methods}>
      <LiveStreamTemplate
        title="Edição de uma transmissão ao vivo"
        view={isView}
        submit={submit}
        isLoading={loading}
        content={content}
      />
    </FormProvider>
  );
}
