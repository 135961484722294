import { KeyboardArrowRight } from '@mui/icons-material';
import React, { memo, useState } from 'react';
import { Outlet } from 'react-router-dom';

import Header from '../Header';
import SideBar from '../SideBar';
import * as S from './styles';

function Layout(): React.ReactElement {
  const [isOpen, setIsOpen] = useState(true);

  const handleOpenNavBar = () => {
    if (!isOpen) {
      setIsOpen(true);
    }
  };

  return (
    <S.Wrapper>
      <S.Aside isOpen={isOpen} onClick={handleOpenNavBar}>
        <SideBar isOpen={isOpen} />
        <S.NavButton
          title={isOpen ? 'Esconder o menu' : 'Abrir menu'}
          type="button"
          isOpen={isOpen}
          onClick={() => setIsOpen(!isOpen)}
        >
          <KeyboardArrowRight />
        </S.NavButton>
      </S.Aside>

      <S.Content isOpen={isOpen}>
        <Header />

        <S.Main>
          <Outlet />
        </S.Main>
      </S.Content>
    </S.Wrapper>
  );
}

export default memo(Layout);
