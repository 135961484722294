import { DataGrid, DataGridProps, ptBR } from '@mui/x-data-grid';
import React from 'react';

import CustomLoading from '../CustomLoading';
import * as S from './styles';

export type TableProps = {
  keyId: string;
  mode?: 'server' | 'client';
  width?: string;
  height?: string;
  handleExport?: () => void;
} & DataGridProps;

export default function Table({
  keyId,
  mode = 'server',
  width,
  height = '550px',
  ...props
}: TableProps): React.ReactElement {
  return (
    <S.Wrapper width={width} height={height}>
      <DataGrid
        {...props}
        sortingOrder={['desc', 'asc']}
        components={{
          ...props?.components,
          LoadingOverlay() {
            return <CustomLoading />;
          },
        }}
        rowHeight={95}
        aria-labelledb={keyId}
        getRowId={row => row[keyId]}
        paginationMode={mode}
        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
      />
    </S.Wrapper>
  );
}
