import { z } from 'zod';

import { requiredMessage } from '../../constants/ErroMessages';
import { categorySchema } from './categoryValidation';
import { optionSelectSchema } from './optionSelectValidation';
import { TagSchema } from './tagValidation';

export const LiveContentSchema = z
  .object({
    authors: z.array(z.string()),
    categories: z
      .array(z.union([z.string(), categorySchema]), {
        required_error: 'Este campo é obrigatório',
      })
      .refine(val => {
        const isArrayOfStrings = val.every(item => typeof item === 'string');
        const isArrayOfObjects = val.every(
          item => categorySchema.safeParse(item).success,
        );

        return isArrayOfStrings || isArrayOfObjects;
      }),
    company_units: z
      .array(optionSelectSchema, {
        required_error: 'Este campo é obrigatório',
      })
      .superRefine((val, ctx) => {
        if (!val.length) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: requiredMessage,
          });
        }
      }),
    event: z.object({
      channel_id: z.string().nonempty(requiredMessage),
      finish_at: z.date({ required_error: requiredMessage }),
      start_at: z.date({ required_error: requiredMessage }),
      chat_url: z
        .string()
        .max(70, 'O resumo não poder conter mais que 70 caracteres')
        .nonempty(requiredMessage),
    }),
    page_title: z
      .string()
      .nonempty(requiredMessage)
      .max(55, 'O título da página não poder conter mais que 55 caracteres'),
    resume: z
      .string()
      .nonempty(requiredMessage)
      .max(160, 'O resumo não poder conter mais que 160 caracteres'),
    requester_id: z.string().uuid().nonempty('Este campo é obrigatório'),
    requester: z.object(
      {
        label: z.string(),
        value: z.string(),
      },
      { required_error: 'Este campo é obrigatório' },
    ),
    subtitle: z.string().nonempty(requiredMessage),
    slug: z
      .string()
      .nonempty(requiredMessage)
      .max(70, 'O slug da página não poder conter mais que 70 caracteres'),
    title: z
      .string()
      .nonempty(requiredMessage)
      .max(70, 'O título não poder conter mais que 70 caracteres'),
    tags: z.array(TagSchema, { required_error: requiredMessage }),
    thumbnail_file_id: z.string().nonempty(requiredMessage),
    thumbnail_file: z.any().nullable().optional(),
    thumbnail_name: z.string().nullable().default(null).optional(),
    thumbnail_tooltip: z.string().nullable().default(null).optional(),
  })
  .superRefine((val, ctx) => {
    if (!val.authors.length) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: requiredMessage,
        path: ['authors'],
      });
    }
  });
