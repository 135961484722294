import styled, { css } from 'styled-components';

type WrapperProps = {
  isTable: boolean;
};

const wrapperModiffiers = {
  table: theme => css`
    .loader {
      width: 50px;
      background: radial-gradient(
            farthest-side,
            ${theme.colors.sebrae_play_pink_400} 94%,
            #0000
          )
          top/4px 4px no-repeat,
        conic-gradient(#0000 30%, ${theme.colors.sebrae_play_pink_400});
      -webkit-mask: radial-gradient(
        farthest-side,
        #0000 calc(100% - 4px),
        #000 0
      );
    }
  `,
  page: theme => css`
    .loader {
      width: 80px;
      background: radial-gradient(
            farthest-side,
            ${theme.colors.sebrae_play_pink_400} 94%,
            #0000
          )
          top/8px 8px no-repeat,
        conic-gradient(#0000 30%, ${theme.colors.sebrae_play_pink_400});
      -webkit-mask: radial-gradient(
        farthest-side,
        #0000 calc(100% - 8px),
        #000 0
      );
    }
  `,
};

export const Wrapper = styled.div<WrapperProps>`
  ${({ theme, isTable }) => css`
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;

    .loader {
      aspect-ratio: 1;
      border-radius: 50%;

      animation: l13 1s infinite linear;
    }
    @keyframes l13 {
      100% {
        transform: rotate(1turn);
      }
    }

    ${isTable ? wrapperModiffiers.table(theme) : wrapperModiffiers.page(theme)}
  `}
`;
