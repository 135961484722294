import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${() => css`
    display: flex;
    height: 100vh;
  `}
`;

export const LeftContent = styled.aside`
  flex: 0 0 auto;

  *::-webkit-scrollbar {
    width: 8px;
  }

  *::-webkit-scrollbar-track {
    background: white;
  }

  *::-webkit-scrollbar-thumb {
    background: lightgray;
  }
`;

type AsideProps = Pick<NavButtonProps, 'isOpen'>;

const asideBarModifiers = {
  true: () => css`
    width: 100%;
  `,
  false: () => css`
    width: 65px;
  `,
};

export const Aside = styled.aside<AsideProps>`
  ${({ theme, isOpen }) => css`
    background-color: ${theme.colors.white};
    box-shadow: ${theme.shadow.default};
    box-sizing: border-box;
    height: 100%;
    max-width: 250px;
    position: fixed;
    transition: ${theme.transition.default};

    bottom: 0;
    left: 0;
    top: 0;

    ${asideBarModifiers[isOpen.toString()]}
  `}
`;

type ContentProps = Pick<NavButtonProps, 'isOpen'>;

const contentModifiers = {
  true: () => css`
    margin-left: 250px;
  `,
  false: () => css`
    margin-left: 65px;
  `,
};

export const Content = styled.section<ContentProps>`
  ${({ theme, isOpen }) => css`
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-y: auto;
    transition: ${theme.transition.default};

    ${contentModifiers[isOpen.toString()]}
  `}
`;

export const Main = styled.main`
  ${() => css`
    box-sizing: border-box;
    flex: 1;
    padding: 1rem;

    @media (min-width: 768px) {
      padding: 2rem;
    }
  `}
`;

type NavButtonProps = {
  isOpen: boolean;
};

const navButtonModiffiers = {
  true: () => css`
    svg {
      transform: scaleX(-1);
    }
  `,
  false: () => css`
    svg {
      transform: scaleX(1);
    }
  `,
};

export const NavButton = styled.button<NavButtonProps>`
  ${({ theme, isOpen }) => css`
    align-items: center;
    border-radius: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: ${theme.shadow.default};
    background-color: ${theme.colors.sebrae_play_pink_400};
    color: ${theme.colors.white};
    cursor: pointer;
    display: flex;
    justify-content: center;
    position: absolute;
    height: 28px;
    transition: ${theme.transition.default};
    width: 28px;
    z-index: 1;

    top: 64px;
    right: -14px;

    ${navButtonModiffiers[isOpen.toString()]};
  `}
`;
