import {
  Brightness1,
  CopyAllOutlined,
  LiveTvOutlined,
} from '@mui/icons-material';
import React, { useRef, useState } from 'react';
import Swal from 'sweetalert2';

import Loading from '../../components/Loading';
import { IEvent } from '../../models/content';
import { startEvent, stopEvent } from '../../services/live';
import * as S from './styles';

type LiveEventTemplateProps = {
  event: IEvent;
  isLoading: boolean;
};

const DEFAULT_COPY_STATE = {
  urlStream: false,
  keyStream: false,
};

export default function LiveEventTemplate({
  event,
  isLoading,
}: LiveEventTemplateProps) {
  const [play, setPlay] = useState(false);
  const [copy, setCopy] = useState(DEFAULT_COPY_STATE);

  const urlStreamInput = useRef<HTMLInputElement>(null);
  const keyStreamInput = useRef<HTMLInputElement>(null);

  const handleTransmission = () => {
    try {
      if (play) {
        stopEvent(event.event_id).then(() => setPlay(!play));
      } else {
        startEvent(event.event_id).then(() => setPlay(!play));
      }
    } catch (error: any) {
      console.error(
        `Erro ao ${play ? 'parar' : 'iniciar'} o evento: `,
        error.message,
      );
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: `Erro ao ${play ? 'parar' : 'iniciar'} o evento: ${
          error.message
        }`,
      });
    }
  };

  const handleCopy = (inputRef: React.RefObject<HTMLInputElement>) => {
    const textToCopy = inputRef.current?.value;
    const inputName = inputRef.current?.name;

    if (!textToCopy || !inputName) return;

    navigator.clipboard
      .writeText(textToCopy)
      .then(function () {
        setCopy(state => ({ ...state, [inputName]: true }));

        setTimeout(() => {
          setCopy(state => ({ ...state, [inputName]: false }));
        }, 3000);
      })
      .catch(function (error) {
        console.error('Erro ao copiar: ', error);
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          text: `Erro ao copiar: ${error}`,
        });
      });
  };

  if (isLoading) return <Loading />;

  return (
    <S.Wrapper>
      <S.Container>
        {play ? (
          <S.PlayerContainer>
            <iframe src={event.reference} width="100%" height="100%" />
          </S.PlayerContainer>
        ) : (
          <S.PlayerContainer>
            <span>Previzualização do video</span>
            <span>Aguardando sinal...</span>
          </S.PlayerContainer>
        )}

        <S.BtnTransmission onClick={handleTransmission} isPlay={play}>
          {play ? (
            <span>
              <Brightness1 /> Parar Transmissão
            </span>
          ) : (
            'Iniciar Tramissão'
          )}
        </S.BtnTransmission>

        <S.Content>
          <div>
            <h1>Faça uma transmissão ao vivo com um software de streaming</h1>
            <p>
              Copie e cole a chave de stream em seu software de streaming. Essa
              chave de stream exclusiva informa ao seu software de streaming
              para onde enviar o feed do vídeo e permite que o Instagram o
              aceite.
            </p>
          </div>

          <S.Label isCopy={copy.urlStream}>
            URL do stream
            <div
              title="Clique para copiar a url do stream"
              onClick={() => handleCopy(urlStreamInput)}
            >
              <input
                type="text"
                name="urlStream"
                disabled
                ref={urlStreamInput}
                value={event.encoder_url}
              />
              <span>{copy.urlStream ? 'Copiado' : 'Copiar'}</span>
            </div>
          </S.Label>

          <S.Label isCopy={copy.keyStream}>
            Chave de stream
            <div
              title="Clique para copiar a chave de stream"
              onClick={() => handleCopy(keyStreamInput)}
            >
              <input
                type="text"
                name="keyStream"
                disabled
                ref={keyStreamInput}
                value={event.transmission_key}
              />
              <span>{copy.keyStream ? 'Copiado' : 'Copiar'}</span>
            </div>
          </S.Label>

          <div>
            <h2>Como transmitir</h2>

            <S.Line>
              <CopyAllOutlined />
              <span>
                Copie a chave de stream e insira-a no software de streaming.
              </span>
            </S.Line>

            <S.Line>
              <LiveTvOutlined />
              <span>
                Selecione Iniciar trasmissão assim que seu software de streaming
                se conectar e aparecer como uma prévia.
              </span>
            </S.Line>
          </div>
        </S.Content>
      </S.Container>
    </S.Wrapper>
  );
}
