import styled, { css, DefaultTheme } from 'styled-components';

import { HeadingProps } from '.';

const headerModifiers = {
  true: (theme: DefaultTheme) => css`
    &::after {
      content: ' *';
      color: ${theme.colors.red_400};
    }
  `,
};

export const Heading = styled.h1.attrs<HeadingProps>(({ level }) => ({
  as: `h${level === undefined ? 1 : level}`,
}))<HeadingProps>`
  ${({
    color = 'gray_700',
    size = 'xxxlarge',
    fontWeight = 600,
    lineHeight = '4rem',
    theme,
    required,
  }) => css`
    color: ${theme.colors[color]};
    font-size: ${theme.font.sizes[size]};
    font-weight: ${fontWeight};
    line-height: ${lineHeight};

    ${!!required && headerModifiers.true(theme)};
  `}
`;
