import { CircularProgress } from '@mui/material';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import Swal from 'sweetalert2';

import Logo from '../../assets/logo.svg';
import Button from '../../components/Button/index';
import TextField from '../../components/FormComponent/TextField';
import theme from '../../styles/theme';
import * as S from './styles';

type LoginTemplateProps = {
  loading: boolean;
  submitHandler: (data) => void;
};

export default function LoginTemplate({
  loading,
  submitHandler,
}: LoginTemplateProps): React.ReactElement {
  const { handleSubmit } = useFormContext();

  const handleError = error => {
    console.error(error);
    Swal.fire({
      icon: 'error',
      title: 'Erro ao fazer o login!',
      text: 'Campos inválidos',
      confirmButtonColor: theme.colors.default,
    });
  };

  return (
    <S.Wrapper>
      <S.LoginForm onSubmit={handleSubmit(submitHandler, handleError)}>
        <img src={Logo} alt="Logo Sebrae Play" />
        <S.Container>
          <TextField label="E-mail" name="email" color="white" />
          <TextField
            type="password"
            label="Senha"
            name="password"
            color="white"
          />

          <Button color="teal" variant="contained" type="submit" fullWidth>
            {loading ? (
              <CircularProgress size="1rem" color="inherit" />
            ) : (
              'Login'
            )}
          </Button>
        </S.Container>
      </S.LoginForm>
    </S.Wrapper>
  );
}
