export default {
  grid: {
    container: '130rem',
    gutter: '3rem',
  },
  border: {
    radius: '0.25rem',
  },
  font: {
    family: 'Inter, sans-serif',
    light: 300,
    normal: 400,
    semiBold: 600,
    bold: 700,
    sizes: {
      xxsmall: '0.75rem',
      xsmall: '0.875rem',
      small: '1rem',
      medium: '1.125rem',
      large: '1.25rem',
      xlarge: '1.5rem',
      xxlarge: '2rem',
      huge: '3rem',
    },
  },
  colors: {
    default: '#5e72e4',
    default_200: '#636EA0',
    default_300: '#5e72e4',
    default_400: '#354371',
    default_500: '#021D45',
    default_600: '#011635',
    primary: '#354371',
    dark_blue: '#021D45',
    info_200: '#88e6f7',
    info_400: '#28d2f1',
    info_500: '#0eb8d7',
    info_600: '#0b8fa7',
    info_700: '#086677',
    info_800: '#053d48',
    gray: '#6c6e6f',
    gray_100: '#f6f9fc',
    gray_200: '#e9ecef',
    gray_300: '#dee2e6',
    gray_400: '#ced4da',
    gray_500: '#adb5bd',
    gray_600: '#8898aa',
    gray_700: '#525f7f',
    gray_800: '#32325d',
    gray_900: '#212529',
    white: '#ffffff',
    neutral_50: '#FAFBFF',
    neutral_75: '#F9FAFC',
    neutral_100: '#F4F6FA',
    neutral_200: '#666',
    neutral_300: '#E6E8F0',
    neutral_400: '#D8DAE5',
    neutral_500: '#C1C4D6',
    neutral_600: '#8F95B2',
    neutral_700: '#696F8C',
    neutral_800: '#474D66',
    neutral_900: '#101840',
    yellow_50: '#fef3e6',
    yellow_100: '#fcdab5',
    yellow_200: '#fbc184',
    yellow_400: '#f79021',
    yellow_500: '#de7708',
    yellow_600: '#ad5c06',
    yellow_700: '#7b4204',
    yellow_800: '#4a2803',
    orange_50: '#FDF4F4',
    orange_100: '#F8E3DA',
    orange_200: '#F2C8B6',
    orange_400: '#DE7548',
    orange_500: '#B25E3A',
    orange_600: '#85462B',
    blue_100: '#EBF0FF',
    blue_200: '#a8b3d6',
    blue_400: '#6376b6',
    blue_500: '#495d9c',
    blue_600: '#39487a',
    blue_700: '#293357',
    blue_800: '#181f34',
    green_50: '#eafaf3',
    green_100: '#c0f1dc',
    green_200: '#96e8c5',
    green_400: '#43d697',
    green_500: '#29bc7d',
    green_600: '#209261',
    green_700: '#176946',
    green_800: '#0e3f2a',
    red_50: '#faebeb',
    red_100: '#f0c2c2',
    red_200: '#e69999',
    red_400: '#d24747',
    red_500: '#b82d2d',
    red_600: '#8f2323',
    red_700: '#661919',
    red_800: '#3d0f0f',
    teal_200: '#9adfe5',
    teal_400: '#49c5d0',
    teal_500: '#2facb6',
    teal_600: '#25868e',
    teal_700: '#1a5f65',
    teal_800: '#10393d',
    pink_200: '#F8BBE7',
    pink_400: '#ED55C2',
    pink_500: '#BE449B',
    pink_600: '#8E3374',
    chart_teal: '#74DDE5',
    chart_yellow: '#FAC86B',
    chart_blue: '#678AF7',
    chart_green: '#6BDAAE',
    chart_violet: '#8F59EF',
    chart_pink: '#FD8ADC',
    chart_aqua: '#70B0FF',
    chart_orange: '#FF9D66',
    sebrae_play_pink_200: '#FF98B7',
    sebrae_play_pink_400: '#F06687',
    sebrae_play_pink_500: '#B9335A',
    sebrae_play_pink_600: '#9e2c4c',
  },
  spacings: {
    xxsmall: '0.24rem',
    xsmall: '0.5rem',
    small: '1rem',
    medium: '1.5rem',
    large: '2rem',
    xlarge: '3rem',
    xxlarge: '4rem',
  },
  layers: {
    base: 10,
    menu: 20,
    overlay: 30,
    modal: 40,
    alwaysOnTop: 50,
  },
  transition: {
    default: 'all 0.2s linear',
    fast: '0.1s ease-in-out',
    button: 'all 0.15s ease',
  },
  shadow: {
    default: '0 0 1rem 0 rgba(0,0,0,0.3)',
    form_input: '0 0.3rem 0.2rem rgb(233 236 239 / 5%)',
    button: ' 0 7px 14px rgb(50 50 93 / 10%), 0 3px 6px rgb(0 0 0 / 8%)',
    card: '0px 4px 8px rgba(16, 24, 64, 0.08);',
    elevetion_1: '0px 4px 8px 0px rgba(16, 24, 64, 0.08)',
    elevetion_2: '0px 4px 8px 0px rgba(16, 24, 64, 0.16)',
    elevetion_3: '0px 12px 40px 0px rgba(16, 24, 64, 0.24)',
    card_base: '0 0 2rem 0 rgba(136,152,170,.15)',
  },
} as const;
