import styled, { css } from 'styled-components';

export const NavBar = styled.nav`
  ${() => css`
    display: block;
    height: 100%;
    position: relative;
  `}
`;

export const LogoContainer = styled.div`
  ${({ theme }) => css`
    align-items: center;
    background-color: ${theme.colors.primary};
    display: flex;
    justify-content: center;
    height: 3.75rem;
    position: relative;
    width: 100%;
    padding: 0.5rem;

    img {
      max-width: 100%;
      max-height: 2rem;
    }
  `}
`;

export const Menus = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
`;

export const Container = styled.div`
  ${() => css`
    overflow-y: auto;
    height: 100%;
  `}
`;
