import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Swal from 'sweetalert2';

import { IEvent } from '../../../../models/content';
import { getContentById } from '../../../../services/contents';
import LiveEventTemplate from '../../../../templates/LiveEventTemplate';

export default function LiveEvent() {
  const [content, setContent] = useState<IEvent>({} as IEvent);
  const [loading, setLoading] = useState(false);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const id = searchParams.get('id');

  const getEvent = async (id: string | null): Promise<void> => {
    if (!id) {
      Swal.fire({
        icon: 'error',
        iconColor: '#f5365c',
        title: 'Erro ao buscar o conteúdo!',
        text: 'O id passado na url é inválido',
        buttonsStyling: false,
        customClass: {
          title: 'modal-title',
          htmlContainer: 'modal-html-container',
          confirmButton: 'btn-ok',
        },
      }).then(() => navigate('/live/live-stream'));
      return;
    }

    setLoading(true);

    try {
      const resp = await getContentById(id);
      resp.event && setContent(resp.event);
    } catch (error: any) {
      console.error(error);
      Swal.fire({
        icon: 'error',
        iconColor: '#f5365c',
        title: 'Erro ao buscar o conteúdo!',
        text: error.message || error,
        customClass: {
          confirmButton: 'btn-ok',
        },
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getEvent(id);
  }, []);

  return <LiveEventTemplate event={content} isLoading={loading} />;
}
