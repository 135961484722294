import { Logout } from '@mui/icons-material';
import React from 'react';

import { useAuth } from '../../contexts/AuthContext';
import * as S from './styles';

export default function Header() {
  const { user, logout } = useAuth();

  const userName = user?.name
    .split(' ')
    .map(str => str.at(0))
    .join('');

  return (
    <S.Wrapper>
      <S.Avatar>
        <span>{userName}</span>
      </S.Avatar>

      <S.BtnLogout type="button" title="Sair do admin" onClick={logout}>
        <Logout fontSize="small" />
      </S.BtnLogout>
    </S.Wrapper>
  );
}
