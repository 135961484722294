import React from 'react';
import {
  Navigate,
  Route,
  Routes as Switch,
  useLocation,
} from 'react-router-dom';

import Layout from '../components/Layout';
import { useAuth } from '../contexts/AuthContext';
import Article from '../pages/Content/Article';
import ArtivleCreate from '../pages/Content/Article/ArticleCreate';
import ArticleEdit from '../pages/Content/Article/ArticleEdit';
import Ebook from '../pages/Content/Ebooks';
import EbookCreate from '../pages/Content/Ebooks/EbookCreate';
import EbookEdit from '../pages/Content/Ebooks/EbookEdit';
import Exclusive from '../pages/Content/Exclusive';
import ExclusiveCreate from '../pages/Content/Exclusive/ExclusiveCreate';
import ExclusiveEdit from '../pages/Content/Exclusive/ExclusiveEdit';
import Infographics from '../pages/Content/Infographics';
import InfographicsCreate from '../pages/Content/Infographics/InfographicsCreate';
import InfographicsEdit from '../pages/Content/Infographics/InfographicsEdit';
import KitsTematic from '../pages/Content/kitsTematic';
import KitsTematicCreate from '../pages/Content/kitsTematic/KitsTematicCreate';
import KitsTematicEdit from '../pages/Content/kitsTematic/KitsTematicEdit';
import Magazine from '../pages/Content/Magazine';
import MagazineCreate from '../pages/Content/Magazine/MagazineCreate';
import MagazineEdit from '../pages/Content/Magazine/MagazineEdit';
import ManagementTools from '../pages/Content/ManagementTools';
import ManagementToolsCreate from '../pages/Content/ManagementTools/ManagementToolsCreate';
import ManagementToolsEdit from '../pages/Content/ManagementTools/ManagementToolsEdit';
import Podcasts from '../pages/Content/Podcast';
import PodcastCreate from '../pages/Content/Podcast/PodcastCreate';
import PodcastEdit from '../pages/Content/Podcast/PodcastEdit';
import Researches from '../pages/Content/Research';
import ResearchesCreate from '../pages/Content/Research/ResearchesCreate';
import ResearchesEdit from '../pages/Content/Research/ResearchesEdit';
import Video from '../pages/Content/Videos';
import VideoCreate from '../pages/Content/Videos/VideoCreate';
import VideoEdit from '../pages/Content/Videos/VideoEdit';
import ContentPage from '../pages/General';
import LiveStream from '../pages/Live/LiveStream';
import LiveStreamCreate from '../pages/Live/LiveStream/Create';
import LiveStreamEdit from '../pages/Live/LiveStream/Edit';
import LiveEvent from '../pages/Live/LiveStream/LiveEvent';
import CoursePage from '../pages/LMS/Course';
import CourseCreate from '../pages/LMS/Course/CourseCreate';
import CourseEdit from '../pages/LMS/Course/CourseEdit';
import EpisodePage from '../pages/LMS/Episode';
import CreateAndEditEpisode from '../pages/LMS/Episode/CreateAndEditEpisode';
import LessonPage from '../pages/LMS/Lesson';
import CreateAndEditLesson from '../pages/LMS/Lesson/CreateAndEditLesson';
import ModulePage from '../pages/LMS/Modules';
import CreateAndEditModule from '../pages/LMS/Modules/CreateAndEditModules';
import SeasonsPage from '../pages/LMS/Seasons';
import CreateAndEditSeasons from '../pages/LMS/Seasons/CreateAndEditSeasons';
import SeriesPage from '../pages/LMS/Series';
import SerieCreate from '../pages/LMS/Series/SerieCreate';
import SerieEdit from '../pages/LMS/Series/SerieEdit';
import Author from '../pages/Records/Author';
import CreateAndEditAuthors from '../pages/Records/Author/CreateAndEditAuthors';
import CategorizationPage from '../pages/Records/Categorization';
import CreateAndEditCategorization from '../pages/Records/Categorization/CreateAndEditCategorization';
import CollectionPage from '../pages/Records/Collection';
import CollectionCreate from '../pages/Records/Collection/CollectionCreate';
import CollectionEdit from '../pages/Records/Collection/CollectionEdit';
import ConsumptionRecord from '../pages/Reports/ConsumptionRecord';
import ConsumptionRecordEdit from '../pages/Reports/ConsumptionRecordEdit';
import CourseAndSerie from '../pages/Reports/CourseAndSerieConsumptiom';
import CoursesAndSeries from '../pages/Reports/CoursesAndSeries';
import General from '../pages/Reports/General';
import Login from '../pages/Safe/Login';
import Profile from '../pages/Safe/Profile';
import LessonSync from '../pages/Synchronous/LessonSync';
import CreateAndEditLessonSync from '../pages/Synchronous/LessonSync/CreateAndEditLessonSync';
import SyncClass from '../pages/Synchronous/SyncClass';
import SyncClassCreate from '../pages/Synchronous/SyncClass/SyncClassCreate';
import SyncClassEdit from '../pages/Synchronous/SyncClass/SyncClassEdit';
import SyncCourse from '../pages/Synchronous/SyncCourse';
import SyncCourseCreateAndEdit from '../pages/Synchronous/SyncCourse/SyncCourseCreateAndEdit';
import ModuleSynchronous from '../pages/Synchronous/SyncModule';
import CreateAndEditModuleSynchronous from '../pages/Synchronous/SyncModule/CreateAndEditModules';
import CreateUser from '../pages/Users/CreateUser';
import ModeratorCreate from '../pages/Users/ModeratorCreate';
import ModeratorEdit from '../pages/Users/ModeratorEdit';
import ModeratorList from '../pages/Users/ModeratorList';
import SpeakerList from '../pages/Users/SpeakerList';
import UserList from '../pages/Users/UserList';
import ViewUser from '../pages/Users/ViewUser/indext';

function AuthRequired() {
  const location = useLocation();
  const { user } = useAuth();

  return !user ? (
    <Navigate to="/login" state={{ from: location }} replace />
  ) : (
    <Layout />
  );
}

export default function Routes() {
  return (
    <Switch>
      <Route path="/login" element={<Login />} />
      <Route path="/" element={<AuthRequired />}>
        <Route path="profile" element={<Profile />} />

        <Route path="general" element={<ContentPage />} />

        <Route path="content">
          <Route path="article" element={<Article />} />
          <Route path="article/create" element={<ArtivleCreate />} />
          <Route path="article/edit" element={<ArticleEdit />} />
          <Route path="exclusive" element={<Exclusive />} />
          <Route path="exclusive/create" element={<ExclusiveCreate />} />
          <Route path="exclusive/edit" element={<ExclusiveEdit />} />
          <Route path="ebooks" element={<Ebook />} />
          <Route path="ebooks/create" element={<EbookCreate />} />
          <Route path="ebooks/edit" element={<EbookEdit />} />
          <Route path="management-tools" element={<ManagementTools />} />
          <Route
            path="management-tools/create"
            element={<ManagementToolsCreate />}
          />
          <Route
            path="management-tools/edit"
            element={<ManagementToolsEdit />}
          />

          <Route path="infographics" element={<Infographics />} />
          <Route path="infographics/create" element={<InfographicsCreate />} />
          <Route path="infographics/edit" element={<InfographicsEdit />} />

          <Route path="thematic-kits" element={<KitsTematic />} />
          <Route path="thematic-kits/create" element={<KitsTematicCreate />} />
          <Route path="thematic-kits/edit" element={<KitsTematicEdit />} />

          <Route path="research" element={<Researches />} />
          <Route path="research/create" element={<ResearchesCreate />} />
          <Route path="research/edit" element={<ResearchesEdit />} />

          <Route path="podcast" element={<Podcasts />} />
          <Route path="podcast/create" element={<PodcastCreate />} />
          <Route path="podcast/edit" element={<PodcastEdit />} />

          <Route path="magazine" element={<Magazine />} />
          <Route path="magazine/create" element={<MagazineCreate />} />
          <Route path="magazine/edit" element={<MagazineEdit />} />

          <Route path="video" element={<Video />} />
          <Route path="video/create" element={<VideoCreate />} />
          <Route path="video/edit" element={<VideoEdit />} />
        </Route>

        <Route path="registration">
          <Route path="collections" element={<CollectionPage />} />
          <Route path="collections/create" element={<CollectionCreate />} />
          <Route path="collections/edit" element={<CollectionEdit />} />
          <Route path="author" element={<Author />} />
          <Route path="categorization" element={<CategorizationPage />} />
        </Route>

        <Route path="synchronous">
          <Route path="class" element={<SyncClass />} />
          <Route path="class/create" element={<SyncClassCreate />} />
          <Route path="class/edit" element={<SyncClassEdit />} />
          <Route path="lesson" element={<LessonSync />} />
          <Route path="lesson/create" element={<CreateAndEditLessonSync />} />
          <Route path="lesson/edit" element={<CreateAndEditLessonSync />} />
          <Route path="modules" element={<ModuleSynchronous />} />
          <Route
            path="modules/create"
            element={<CreateAndEditModuleSynchronous />}
          />
          <Route
            path="modules/edit"
            element={<CreateAndEditModuleSynchronous />}
          />
          <Route path="course" element={<SyncCourse />} />
          <Route path="course/create" element={<SyncCourseCreateAndEdit />} />
          <Route path="course/edit" element={<SyncCourseCreateAndEdit />} />
        </Route>

        <Route path="live">
          <Route path="live-stream" element={<LiveStream />} />
          <Route path="live-stream/create" element={<LiveStreamCreate />} />
          <Route path="live-stream/edit" element={<LiveStreamEdit />} />
          <Route path="live-stream/event" element={<LiveEvent />} />
        </Route>

        <Route path="lms">
          <Route path="lessons" element={<LessonPage />} />
          <Route path="modules" element={<ModulePage />} />
          <Route path="courses" element={<CoursePage />} />
          <Route path="courses/create" element={<CourseCreate />} />
          <Route path="courses/edit" element={<CourseEdit />} />
        </Route>

        <Route path="series-category">
          <Route path="episodes" element={<EpisodePage />} />
          <Route path="seasons" element={<SeasonsPage />} />

          <Route path="series" element={<SeriesPage />} />
          <Route path="series/create" element={<SerieCreate />} />
          <Route path="series/edit" element={<SerieEdit />} />
        </Route>

        <Route path="reports">
          <Route path="general" element={<General />} />
          <Route path="course-serie" element={<CourseAndSerie />} />
          <Route path="consumption" element={<ConsumptionRecord />} />
          <Route
            path="consumption-record/edit"
            element={<ConsumptionRecordEdit />}
          />
        </Route>

        <Route path="users">
          <Route path="list" element={<UserList />} />
          <Route path="create" element={<CreateUser />} />
          <Route path="view" element={<ViewUser />} />

          <Route path="moderator">
            <Route path="list" element={<ModeratorList />} />
            <Route path="create" element={<ModeratorCreate />} />
            <Route path="edit/:slug" element={<ModeratorEdit />} />
          </Route>

          <Route path="speaker">
            <Route path="list" element={<SpeakerList />} />
          </Route>
        </Route>

        <Route
          path="/categorization/createAndEditCategorization"
          element={<CreateAndEditCategorization />}
        />

        <Route
          path="/lesson/createAndEditLesson"
          element={<CreateAndEditLesson />}
        />

        <Route
          path="/module/createAndEditModule"
          element={<CreateAndEditModule />}
        />

        <Route
          path="/seasons/createAndEditSeasons"
          element={<CreateAndEditSeasons />}
        />

        <Route
          path="/episodes/createAndEditEpisodes"
          element={<CreateAndEditEpisode />}
        />

        <Route
          path="/author/createAndEditAuthors"
          element={<CreateAndEditAuthors />}
        />
        <Route path="/general" element={<General />} />
        <Route path="/reportList" element={<CoursesAndSeries />} />
      </Route>
    </Switch>
  );
}
