import { useEffect, useState } from 'react';
import { Option } from 'react-select/src/filters';

import { getAllCompanyUnit } from '../services/company';
import { getAllRequesters } from '../services/requester';

type OptionsToSelectType = {
  company_units: Option[] | [];
  requesters: Option[] | [];
};

export default function useApplicant() {
  const [optionsToSelect, setOptionsToSelect] = useState<OptionsToSelectType>({
    company_units: [],
    requesters: [],
  });

  const getOptionsForSelect = async () => {
    const fetchData = async (getDataFunc, key, keyId) => {
      const data = await getDataFunc();

      if (!data[key].length) return [];

      return data[key].map(item => ({
        label: item['name'],
        value: item[keyId],
      }));
    };

    const options = await Promise.all([
      fetchData(getAllCompanyUnit, 'companies_unit', 'company_unit_id'),
      fetchData(getAllRequesters, 'requesters', 'requester_id'),
    ]);

    setOptionsToSelect({
      company_units: options[0],
      requesters: options[1],
    });
  };

  useEffect(() => {
    getOptionsForSelect();
  }, []);

  return { optionsToSelect, setOptionsToSelect };
}
