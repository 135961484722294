import { EditOutlined, VisibilityOutlined } from '@mui/icons-material';
import { format } from 'date-fns';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Link, useNavigate } from 'react-router-dom';

import Button from '../../../components/Button';
import { FlexContainer } from '../../../components/FlexContainer';
import { TableProps } from '../../../components/Table';
import { useGetContent, useTablePagination } from '../../../hooks';
import useSearchContents from '../../../hooks/useSearchContents';
import { IClass } from '../../../models/SyncCourse';
import { listSyncClass } from '../../../services/syncCourse';
import ContentTemplate from '../../../templates/ContentTemplate';
import { createHeaders } from '../../../utils';
import { handleSort } from '../../../utils/handleSort';

enum ClassStatus {
  ACTIVE = 'Ativo',
  INACTIVE = 'Inativo',
}

enum ClassAudience {
  OPEN = 'Aberto',
  CLOSED = 'Fechado',
}

export default function SyncClass(): JSX.Element {
  const [classes, setClasses] = useState<IClass[] | []>([]);
  const [orderBySort, setOrderBySort] = useState('DESC');
  const [orderBy, setOrderBy] = useState<
    'start_at' | 'finish_at' | 'created_at' | 'updated_at'
  >('updated_at');

  const inputRef = useRef<HTMLInputElement | null>(null);
  const navigate = useNavigate();
  const { paginationModel, rowCount, setRowCount, changePage, changePageSize } =
    useTablePagination();
  const { getVariedContents, loading } = useGetContent(listSyncClass);
  const { handleParams } = useSearchContents();

  const showCourse = useCallback(
    (id: string) => {
      navigate(`/synchronous/class/edit?id=${id}&view=true`);
    },
    [navigate],
  );

  const editCourse = useCallback(
    (id: string) => {
      navigate(`/synchronous/class/edit?id=${id}`);
    },
    [navigate],
  );

  const breadcrumbs = [
    <Link key="profile" to="/">
      Home
    </Link>,
    <span key="sync">Curso Síncrono</span>,
    <span key="sync-class">Turma</span>,
  ];

  const headerStyles: React.CSSProperties = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  };

  const columns = useMemo(
    () => [
      {
        headerName: 'Metodologia',
        field: 'row.content.sebrae_code',
        minWidth: 120,

        renderCell: (cellValues: any) => (
          <p style={headerStyles} title={cellValues.row?.content?.sebrae_code}>
            {cellValues.row?.content?.sebrae_code}
          </p>
        ),
        sortable: false,
      },
      {
        headerName: 'Nome do curso',
        field: 'row.name',
        minWidth: 250,

        renderCell: (cellValues: any) => (
          <p style={headerStyles} title={cellValues.row?.name}>
            {cellValues.row?.name}
          </p>
        ),
        sortable: false,
      },
      createHeaders('Código da Turma', 'sebrae_code', 150),
      {
        headerName: 'Status',
        field: 'status',
        minWidth: 80,

        renderCell: (cellValues: any) => (
          <p style={headerStyles} title={cellValues?.value}>
            {ClassStatus[cellValues?.value.toUpperCase()]}
          </p>
        ),
        sortable: false,
      },
      {
        headerName: 'Nome da turma',
        field: 'name',
        minWidth: 150,

        renderCell: (cellValues: any) => (
          <p style={headerStyles} title={cellValues.row?.name}>
            {cellValues.value}
          </p>
        ),
        sortable: false,
      },
      {
        headerName: 'Preço',
        field: 'price',
        minWidth: 80,

        renderCell: (cellValues: any) => (
          <p style={headerStyles} title={cellValues.value}>
            {Number(cellValues.value).toLocaleString('pt-BR', {
              currency: 'BRL',
              style: 'currency',
            })}
          </p>
        ),
        sortable: false,
      },
      {
        headerName: 'Tipo',
        field: 'audience',
        minWidth: 40,

        renderCell: (cellValues: any) => (
          <p style={headerStyles} title={cellValues?.value}>
            {ClassAudience[cellValues?.value.toUpperCase()]}
          </p>
        ),
        sortable: false,
      },
      createHeaders('Vagas', 'vacancies', 80),
      {
        headerName: 'Instrutor',
        field: 'row.author.name',
        minWidth: 200,
        renderCell: (cellValues: any) => (
          <p style={headerStyles} title={cellValues.row?.name}>
            {cellValues.row.author?.name}
          </p>
        ),
        sortable: false,
      },
      {
        headerName: 'Data Início',
        field: 'start_at',
        minWidth: 150,
        renderCell: (cellValues: any) => (
          <p style={{ whiteSpace: 'normal' }}>
            {format(new Date(cellValues?.value), 'dd/MM/yyyy HH:mm')}
          </p>
        ),
      },
      {
        headerName: 'Data Fim',
        field: 'finish_at',
        minWidth: 150,
        renderCell: (cellValues: any) => (
          <p style={{ whiteSpace: 'normal' }}>
            {format(new Date(cellValues?.value), 'dd/MM/yyyy HH:mm')}
          </p>
        ),
      },
      {
        headerName: 'Ações',
        field: 'actions',
        minWidth: 100,
        getRowHeight: 100,
        flex: 1,
        sortable: false,
        renderCell: cellValues => (
          <FlexContainer
            gap="0.4rem"
            flexWrap="wrap"
            justifyContent="flex-start"
          >
            <Button
              onClick={() => editCourse(cellValues.id)}
              icon={<EditOutlined fontSize="inherit" />}
              color="secondary"
              title="Editar"
            />
            <Button
              onClick={() => showCourse(cellValues.id)}
              icon={<VisibilityOutlined fontSize="inherit" />}
              color="secondary"
              title="Visualizar"
            />
          </FlexContainer>
        ),
      },
    ],
    [],
  );

  const handleSearch = async () => {
    const params = handleParams(
      {
        limit: paginationModel.pageSize,
        offset: 1,
        order_by_sort: orderBySort,
        order_by: orderBy,
      },
      inputRef.current?.value,
    );

    await getVariedContents(setClasses, setRowCount, params);
  };

  useEffect(() => {
    getVariedContents(setClasses, setRowCount, {
      limit: paginationModel.pageSize,
      offset: paginationModel.page,
      order_by_sort: orderBySort,
      order_by: orderBy,
    });
  }, [paginationModel.page, paginationModel.pageSize, orderBySort, orderBy]);

  const tableProps: TableProps = {
    columns: columns,
    rows: classes,
    keyId: 'class_id',
    onPageChange: page => changePage(page),
    onPageSizeChange: pageSize => changePageSize(pageSize),
    page: paginationModel.page - 1,
    rowsPerPageOptions: [10, 25, 50, 75, 100],
    pageSize: paginationModel.pageSize,
    rowCount,
    loading,
    onSortModelChange: sortModel =>
      handleSort({
        changePage,
        setOrderBy,
        setOrderBySort,
        sortModel,
        letterConverter: 'toLowerCase',
      }),
    disableColumnMenu: true,
  };

  return (
    <ContentTemplate
      key="async-class-table"
      pageName="lista de turmas"
      breadcrumbs={breadcrumbs}
      handleSearch={handleSearch}
      linkToCreatePage="create"
      ref={inputRef}
      {...tableProps}
    />
  );
}
