import styled from 'styled-components';

export const GridOverlay = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .ant-empty-img-1 {
    fill: '#aeb8c2';
  }

  & .ant-empty-img-2 {
    fill: '#f5f5f7';
  }

  & .ant-empty-img-3 {
    fill: '#dce0e6';
  }

  & .ant-empty-img-4 {
    fill: '#fff';
  }

  & .ant-empty-img-5 {
    fill-opacity: '0.8';
    fill: '#f5f5f5';
  }
`;
