import { zodResolver } from '@hookform/resolvers/zod';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { z } from 'zod';

import { ContentStatusType } from '../../../../common/types/contentStatusType';
import { LiveContentSchema } from '../../../../common/validations/liveValidation';
import { useContentSubmit } from '../../../../hooks';
import { createContent } from '../../../../services/contents';
import LiveStreamTemplate from '../../../../templates/LiveStreamTemplate';

export type LiveFormValues = z.infer<typeof LiveContentSchema>;

export const LIVE_INITAL_VALUES = {
  authors: [],
  categories: [],
  thumbnail_file_id: '',
};

export default function LiveStreamCreate() {
  const methods = useForm<LiveFormValues>({
    resolver: zodResolver(LiveContentSchema),
    defaultValues: LIVE_INITAL_VALUES,
  });
  const navigate = useNavigate();

  const submit = (data: LiveFormValues, status: ContentStatusType) => {
    const { obj } = useContentSubmit({ data });

    delete obj['thumbnail_file'];
    delete obj['thumbnail_tooltip'];

    obj['company_units'] = data['company_units'].map(company => company.value);
    obj['tags'] = data['tags'].map(tag => tag.tag_id);
    obj['status'] = status;
    obj['type'] = 'live';
    obj['description'] = data['resume'];
    obj['review_in'] = data['event'].finish_at;

    createContent(obj)
      .then(() => {
        Swal.fire({
          title: 'Transmissão criada com sucesso',
          icon: 'success',
          customClass: {
            title: 'modal-title',
            htmlContainer: 'modal-html-container',
            confirmButton: 'btn-ok',
          },
          buttonsStyling: false,
        }).then(() => navigate(-1));
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'Algo deu errado',
          iconColor: '#f5365c',
          text: error.message,
          customClass: {
            title: 'modal-title',
            htmlContainer: 'modal-html-container',
            confirmButton: 'btn-ok',
          },
          buttonsStyling: false,
        });
      });
  };

  return (
    <FormProvider {...methods}>
      <LiveStreamTemplate
        title="Criação de uma transmissão ao vivo"
        view={false}
        submit={submit}
      />
    </FormProvider>
  );
}
