import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

import { ContentType } from '../../../common/enum/contentType';
import { TableProps } from '../../../components/Table';
import { useGetContent, useTablePagination } from '../../../hooks';
import useContentColumns from '../../../hooks/useContentColumns';
import ContentFromReponse from '../../../models/from-api-response/content';
import { deleteContent, listContents } from '../../../services/contents';
import ContentTemplate from '../../../templates/ContentTemplate';
import { handleSort } from '../../../utils/handleSort';

export default function LiveStream() {
  const [content, setContent] = useState<ContentFromReponse[] | []>([]);
  const [orderBySort, setOrderBySort] = useState('DESC');
  const [orderBy, setOrderBy] = useState('UPDATED_AT');

  const navigate = useNavigate();
  const inputRef = useRef<HTMLInputElement>(null);
  const { paginationModel, rowCount, setRowCount, changePage, changePageSize } =
    useTablePagination();
  const { getVariedContents, loading } = useGetContent(listContents);

  const handleSearch = async () => {
    const params = {
      limit: paginationModel.pageSize,
      offset: paginationModel.page,
      order_by_sort: orderBySort,
      order_by: orderBy,
      types: [ContentType['LIVE']],
    };

    if (inputRef.current?.value.length)
      params['search'] = inputRef.current?.value;

    await getVariedContents(setContent, setRowCount, params);
  };

  const breadcrumbs = [
    <Link key="profile" to="/">
      Home
    </Link>,
    <Link key="live" to="/live">
      Ao Vivo
    </Link>,
    <span key="live-stream">Transmissão Ao Vivo</span>,
  ];

  const handleRemove = useCallback(async (contentId: string) => {
    Swal.fire({
      title: '<strong>Confirmação</strong>',
      html: 'Tem certeza que deseja remover este conteúdo?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      focusConfirm: false,
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          await deleteContent(contentId);

          Swal.fire({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Conteúdo excluído com sucesso!',
          });

          await getVariedContents(setContent, setRowCount, {
            limit: paginationModel.pageSize,
            offset: 1,
            order_by_sort: 'DESC',
            types: [ContentType['LIVE']],
          });
        } catch (error: any) {
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text: `Erro ao excluir conteúdo. ${error.message}`,
          });
        }
      }
    });
  }, []);

  const handleEdit = useCallback(
    (contentId: string) => {
      navigate(`/live/live-stream/edit?id=${contentId}`);
    },

    [navigate],
  );

  const handleShow = useCallback(
    (contentId: string) => {
      navigate(`/live/live-stream/edit?id=${contentId}&view=true`);
    },

    [navigate],
  );

  const { liveColumns } = useContentColumns({
    handleRemove,
    handleDuplicate: undefined,
    handleEdit,
    handleShow,
    complementLink: 'ao-vivo',
  });

  const tableProps: TableProps = {
    columns: liveColumns,
    rows: content,
    keyId: 'content_id',
    onPageChange: page => changePage(page),
    onPageSizeChange: pageSize => changePageSize(pageSize),
    page: paginationModel.page - 1,
    rowsPerPageOptions: [10, 25, 50, 75, 100],
    pageSize: paginationModel.pageSize,
    rowCount,
    loading,
    onSortModelChange: sortModel =>
      handleSort({ changePage, setOrderBy, setOrderBySort, sortModel }),
    disableColumnMenu: true,
  };

  useEffect(() => {
    getVariedContents(setContent, setRowCount, {
      limit: paginationModel.pageSize,
      offset: paginationModel.page,
      order_by_sort: orderBySort,
      order_by: orderBy,
      types: [ContentType['LIVE']],
    });
  }, [paginationModel.page, paginationModel.pageSize, orderBySort, orderBy]);

  return (
    <ContentTemplate
      key={'live-stream-table'}
      pageName="transmissão ao vivo"
      linkToCreatePage="/live/live-stream/create"
      breadcrumbs={breadcrumbs}
      handleSearch={handleSearch}
      ref={inputRef}
      {...tableProps}
    />
  );
}
