import React from 'react';

import * as S from './styles';

type SpinnerProps = {
  isTable?: boolean;
};

export default function Spinner({ isTable = false }: SpinnerProps) {
  return (
    <S.Wrapper isTable={isTable}>
      <div className="loader" />
    </S.Wrapper>
  );
}
