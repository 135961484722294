import { AddCircle, Delete, ViewList } from '@mui/icons-material';
import React, { useEffect, useRef, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { createPortal } from 'react-dom';
import { useFormContext } from 'react-hook-form';

import { KeyIds } from '../../common/enum/keyIds';
import { IMetaDataProps } from '../../common/interfaces/metaData';
import { modalWithoutSchema } from '../../common/validations/modalWithoutSchema';
import { modalWithSlugSchema } from '../../common/validations/modalWithSlugSchema';
import useListColumns from '../../hooks/useListColumns';
import Button from '../Button/index';
import Container from '../Drag/Container';
import { FlexContainer } from '../FlexContainer';
import TextField from '../FormComponent/TextField';
import GenericContentModal from '../SimpleModal/GenericContentModal';
import RegisterModal from '../SimpleModal/RegisterModal';
import * as S from './styles';

type GenericDragAndDropProps = {
  view: boolean;
  needSlug?: boolean;
  required?: boolean;
  keyId: KeyIds;
  name: string;
  label: string;
  getItens: (args?: any) => Promise<{
    data: any[];
    metaData: IMetaDataProps;
  }>;
  createItens: (data) => Promise<any>;
};

type Item = {
  [key: string]: any;
};

export default function GenericDragAndDrop<T>({
  view,
  required = false,
  needSlug = false,
  keyId,
  getItens,
  createItens,
  name,
  label,
}: GenericDragAndDropProps) {
  const [isOpenModal, setIsOpenModal] = useState({
    list: false,
    addNew: false,
  });
  const [selectedItens, setselectedItens] = useState<any[]>([]);

  const {
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();
  const ordersToRef = useRef<T[]>();

  ordersToRef.current = selectedItens;

  const courseId = new URLSearchParams(location.search).get('id');

  const handleItens = async () => {
    const itens = getValues(name);

    if (itens !== undefined) {
      setselectedItens(itens);
    }
  };

  useEffect(() => {
    handleItens();
  }, [courseId]);

  const handleCloseModal = (key: 'list' | 'addNew') => {
    setIsOpenModal(prevState => ({ ...prevState, [key]: false }));
  };
  const handleSelected = <T extends Item>(item: T) => {
    setselectedItens(prevState => [...prevState, item]);

    const itens = getValues(name);

    itens === undefined
      ? setValue(name, [item])
      : setValue(name, [...itens, item]);
  };

  const onChangeDeleteItem = <T extends Item>(item: T) => {
    const updatedOrder = ordersToRef.current?.filter(
      element => element[keyId] !== item[keyId],
    );

    if (!updatedOrder?.length) {
      setselectedItens([]);
      setValue(name, []);
    } else {
      setselectedItens(updatedOrder);
      setValue(name, updatedOrder);
    }
  };

  const dealWithRemoveAll = () => {
    setselectedItens([]);
    setValue(name, []);
  };

  const { columns } = useListColumns(keyId, selectedItens, handleSelected);

  const handleRequiredError = error => {
    return error[name].message;
  };

  return (
    <S.Wrapper>
      <FlexContainer gap="0.875rem" flexDirection="column">
        <TextField
          label={label}
          name={name}
          required={required}
          disabled={view}
        >
          <DndProvider backend={HTML5Backend}>
            <S.Container>
              <Container
                item={selectedItens}
                setItem={setselectedItens}
                onChangeDeleteItem={item => onChangeDeleteItem(item)}
              />
            </S.Container>
          </DndProvider>
          {selectedItens.length === 0 && (
            <S.Container>
              <S.Text>Nenhum conteúdo selecionado</S.Text>
            </S.Container>
          )}
        </TextField>

        <FlexContainer
          alignItems="center"
          gap="0.875rem"
          justifyContent="center"
          width="100%"
        >
          <FlexContainer gap="0.875rem" flexWrap="wrap">
            <Button
              type="button"
              variant="contained"
              color="info"
              disabled={view}
              startIcon={<ViewList />}
              onClick={() =>
                setIsOpenModal(prevState => ({ ...prevState, list: true }))
              }
            >
              <span>Listar</span>
            </Button>
            <Button
              type="button"
              variant="contained"
              disabled={view}
              startIcon={<AddCircle />}
              onClick={() =>
                setIsOpenModal(prevState => ({ ...prevState, addNew: true }))
              }
            >
              <span> Adicionar novo</span>
            </Button>
            <Button
              type="button"
              variant="contained"
              color="error"
              disabled={view}
              onClick={dealWithRemoveAll}
              startIcon={<Delete />}
            >
              <span>Remover Todos</span>
            </Button>
          </FlexContainer>
        </FlexContainer>

        {Boolean(errors[name]) && (
          <S.InvalidFeedback>
            {handleRequiredError(errors[name])}
          </S.InvalidFeedback>
        )}
      </FlexContainer>

      {isOpenModal.list &&
        createPortal(
          <GenericContentModal
            columns={columns[keyId]}
            isOpen={isOpenModal.list}
            onCloseChange={() => handleCloseModal('list')}
            callback={getItens}
            keyId={keyId}
            key={'list-modal'}
          />,
          document.body,
        )}
      {isOpenModal.addNew &&
        createPortal(
          <RegisterModal
            schema={needSlug ? modalWithSlugSchema : modalWithoutSchema}
            isOpen={isOpenModal.addNew}
            onCloseChange={() => handleCloseModal('addNew')}
            keyId={keyId}
            funcCreate={createItens}
            handleOptionChange={({ data }) => handleSelected(data)}
            needSlug={needSlug}
          />,
          document.body,
        )}
    </S.Wrapper>
  );
}
