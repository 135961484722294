export function createHeaders(
  headerName: string,
  field: string,
  minWidth?: number,
  flex?: number,
) {
  return {
    headerName,
    field,
    minWidth,
    flex: flex ? flex : 1,
    sortable: false,
  };
}
